import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

const StagesTable_GetData = gql(`
query StagesTable_GetData($platformId: UUID!, $platformIdProvided: Boolean!) {
	...StagesTable_Query
}
`);

export function useStagesTableData(platformId?: string) {
	const { data, loading, error } = useQuery(StagesTable_GetData, {
		errorPolicy: "all",
		variables: {
			platformId: platformId || uuidv4(),
			platformIdProvided: !!platformId,
		},
	});
	const memoizedData = useMemo(() => data || undefined, [data]);
	return {
		data: memoizedData,
		loading,
		error,
	};
}
