import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

const PlatformsTable_GetData = gql(`
query PlatformsTable_GetData {
	...PlatformsTable_Query
}
`);

export function usePlatformsTableData() {
	const { data, loading, error } = useQuery(PlatformsTable_GetData, {
		errorPolicy: "all",
	});
	const memoizedData = useMemo(() => data || undefined, [data]);
	return {
		data: memoizedData,
		loading,
		error,
	};
}
