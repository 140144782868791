import { useAuth } from "@/auth/useAuth";
import { useFirebaseAuthProvider } from "@/auth/useFirebaseAuthProvider";
import { SsoButton } from "@/components/SsoButton";
import { useConfirmDesctructiveAction } from "@/hooks/useConfirmDesctructiveAction";
import { Box, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { linkWithPopup, unlink } from "firebase/auth";
import { useState } from "react";

type ToggleSsoProviderProps = {
	provider: "google" | "microsoft";
};

export function ToggleSsoProvider({ provider }: ToggleSsoProviderProps) {
	const { firebaseAuth } = useAuth();
	const enabledProviderIds = firebaseAuth.currentUser?.providerData?.map((p) => p.providerId);
	const providerId = `${provider}.com`;
	const isEnabled = enabledProviderIds?.includes(providerId);
	const providerName = provider === "google" ? "Google" : "Microsoft";
	const firebaseAuthProvider = useFirebaseAuthProvider(provider);
	const [isLoading, setIsLoading] = useState(false);
	const checkMarkColor = "transparent";

	const enableProvider = async () => {
		if (!firebaseAuth.currentUser) {
			return;
		}
		setIsLoading(true);
		try {
			await linkWithPopup(firebaseAuth.currentUser, firebaseAuthProvider);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const disableProvider = async () => {
		if (!firebaseAuth.currentUser) {
			return;
		}
		setIsLoading(true);
		try {
			await unlink(firebaseAuth.currentUser, providerId);
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
		}
	};

	const { protectedAction: protectedDisable, confirm } = useConfirmDesctructiveAction({
		action: disableProvider,
		confirmText: () => providerName,
		message: `Are you sure you want to disable ${providerName} SSO?`,
		buttonText: "Disable",
	});

	return (
		<Box>
			<Text>
				{providerName} SSO is {isEnabled ? "enabled" : "not enabled"}
			</Text>
			<SsoButton
				color={isEnabled ? "red" : "blue"}
				justify="space-between"
				provider={provider}
				fullWidth
				loading={isLoading}
				onClick={isEnabled ? protectedDisable : enableProvider}
				rightSection={<IconCheck color={checkMarkColor} />}
			>
				{isEnabled ? "Disable" : "Enable"}&nbsp;{providerName} SSO
			</SsoButton>
			{confirm}
		</Box>
	);
}
