import { Text } from "@mantine/core";
import type { MRT_Cell, MRT_RowData } from "mantine-react-table";

type RevenueCellProps<TData extends MRT_RowData> = {
	cell: MRT_Cell<TData, number | null>;
};
export function AverageDaysInStageCell<TData extends MRT_RowData>({ cell }: RevenueCellProps<TData>) {
	const days = cell.getValue();
	if (days === undefined || days === null) {
		return (
			<Text c="dimmed" size="sm" fs="italic">
				No data
			</Text>
		);
	}
	if (days < 1) {
		return <span>{"< 1 day"}</span>;
	}
	return <span>{Math.round(days)} days</span>;
}
