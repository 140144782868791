import { type FragmentType, gql, useFragment } from "@/__generated__";
import { SelectPlaform_QueryFragmentDoc, SelectPlatform_PlatformFragmentDoc } from "@/__generated__/graphql";
import { Select, type SelectProps } from "@mantine/core";

export const SelectPlatform_Platform = gql(`
fragment SelectPlatform_Platform on Platform {
	id
	name
}
`);

const SelectPlaform_Query = gql(`
fragment SelectPlaform_Query on Query {
    platforms {
        edges {
            node {
                ...SelectPlatform_Platform
            }
        }
    }
}
`);

interface SelectPlaformProps extends SelectProps {
	platformData?: FragmentType<typeof SelectPlaform_Query>;
}

export function SelectPlaform({ platformData, children, ...props }: SelectPlaformProps) {
	const platformEdges = useFragment(SelectPlaform_QueryFragmentDoc, platformData);
	const data = platformEdges
		? platformEdges.platforms.edges
				.map((e) => useFragment(SelectPlatform_PlatformFragmentDoc, e.node))
				.map((p) => ({
					value: p.id,
					label: p.name,
				}))
		: [];

	return (
		<Select placeholder="Select platform" {...props} searchable data={data}>
			{children}
		</Select>
	);
}
