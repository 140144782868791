import { formatDistanceToNow } from "date-fns";
import type { MRT_Cell, MRT_RowData } from "mantine-react-table";

type TimestampCellProps<TData extends MRT_RowData> = {
	cell: MRT_Cell<TData, Date>;
};

export function TimestampCell<TData extends MRT_RowData>({ cell }: TimestampCellProps<TData>) {
	const value = cell.getValue();
	if (!value) {
		return null;
	}
	return <div>{formatDistanceToNow(value, { addSuffix: true })}</div>;
}
