import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";

const PLATFORM_EXIST_QUERY = gql(`
query SubmitLead_getPlatformExists($platformId: UUID!) {
    ...SubmitLead_Query
}
`);
export function usePlatformExistsForSubmitLead(platformId: string) {
	const { data, loading, error } = useQuery(PLATFORM_EXIST_QUERY, {
		variables: { platformId },
	});
	return {
		data,
		loading,
		error,
	};
}
