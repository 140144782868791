import type { PlatformsTable_PlatformFragment } from "@/__generated__/graphql";
import { generateFullAppUrl } from "@/pages/common/utils";
import { paths } from "@/paths";
import { CopyButton, Menu } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useEffect } from "react";

type SubmitLeadLinkItemProps = {
	copied: boolean;
	copy: () => void;
};

function SubmitLeadLinkItem({ copy, copied }: SubmitLeadLinkItemProps) {
	useEffect(() => {
		if (copied) {
			notifications.show({ message: "Copied submit lead link to clipboard!" });
		}
	}, [copied]);
	return <Menu.Item onClick={copy}>Copy link to submit lead form</Menu.Item>;
}

type CopySubmitLeadLinkItemProps = {
	platform: PlatformsTable_PlatformFragment;
};

export function CopySubmitLeadLinkItem({ platform }: CopySubmitLeadLinkItemProps) {
	return (
		<CopyButton value={generateFullAppUrl(paths.submitLead, { platformId: platform.id })}>
			{({ copied, copy }) => <SubmitLeadLinkItem copied={copied} copy={copy} />}
		</CopyButton>
	);
}
