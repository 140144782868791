import { getConfig } from "@/config";
import { initializeApp } from "firebase/app";
import { EmailAuthProvider, GoogleAuthProvider, OAuthProvider, connectAuthEmulator, getAuth } from "firebase/auth";

const config = getConfig();
const firebaseApp = initializeApp(config.firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
if (config.emulateFirestore) {
	connectAuthEmulator(firebaseAuth, "http://127.0.0.1:9099");
}
export const microsoftAuthProvider = new OAuthProvider("microsoft.com");
export const googleAuthProvider = new GoogleAuthProvider();
export const emailAuthProvider = new EmailAuthProvider();
firebaseAuth.languageCode = "en";
