import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";

const UserForm_GetData = gql(`
query UserForm_GetData($userId: UUID!, $userIdProvided: Boolean!) {
    ...UserForm_Query
}
`);

export function useUserFormData(userId?: string) {
	const { data, loading, error } = useQuery(UserForm_GetData, {
		errorPolicy: "all",
		variables: {
			userId: userId || uuidv4(),
			userIdProvided: !!userId,
		},
	});
	return { data, error, loading };
}
