import { getConfig } from "@/config";
import { FeatureFlagContext, type FeatureFlagContextProps } from "@/featureFlags/FeatureFlagContext";

export function FeatureFlagProvider({ children }: { children: React.ReactNode }) {
	const config = getConfig();
	let value: FeatureFlagContextProps;
	if (config.environment === "production") {
		value = {
			default: false,
		};
	} else {
		value = {
			default: true,
		};
	}

	return <FeatureFlagContext.Provider value={value}>{children}</FeatureFlagContext.Provider>;
}
