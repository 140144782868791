import { type FragmentType, gql, useFragment } from "@/__generated__";
import { AddNewUserFirstInCache_UserFragmentDoc, type UserEdge } from "@/__generated__/graphql";
import type { ApolloCache } from "@apollo/client";

const addNewUserFirstInCache_User = gql(`
fragment addNewUserFirstInCache_User on User {
    ...UsersTable_User
    ...UserForm_User
}
`);

export function addNewUserFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNewUserFirstInCache_User>>,
	user: FragmentType<typeof addNewUserFirstInCache_User>,
) {
	const rootQueryId = cache.identify({ __typename: "Query" });
	const userData = useFragment(AddNewUserFirstInCache_UserFragmentDoc, user);
	cache.modify({
		id: rootQueryId,
		fields: {
			users: (existingUsers) => {
				if (!existingUsers) {
					return {
						edges: [{ node: userData }],
					};
				}
				return {
					edges: [{ node: userData }, ...existingUsers.edges],
				};
			},
		},
	});
}

export function deleteUserFromCache(
	cache: ApolloCache<FragmentType<typeof addNewUserFirstInCache_User>>,
	userId: string,
) {
	const rootQueryId = cache.identify({ __typename: "Query" });
	const userCacheId = cache.identify({ __typename: "User", id: userId });
	cache.modify({
		id: rootQueryId,
		fields: {
			users: (existingUsers) => {
				if (!existingUsers) {
					return null;
				}
				return {
					edges: existingUsers.edges.filter((u: UserEdge) => u.node.id !== userId),
				};
			},
		},
	});
	cache.evict({ id: userCacheId });
}
