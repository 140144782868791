import { type FragmentType, gql, useFragment } from "@/__generated__";
import {
	AddNoteFirstInCache_MerchantNoteFragmentDoc,
	MerchantNote_MerchantNoteFragmentDoc,
} from "@/__generated__/graphql";
import { MerchantNote_MerchantNoteFragment } from "@/pages/merchants/onboarding/merchantNotes/MerchantNote";
import type { ApolloCache } from "@apollo/client";

const addNoteFirstInCache_MerchantNote = gql(`
fragment addNoteFirstInCache_MerchantNote on MerchantNote {
	...MerchantNote_MerchantNote
}
`);

export function addNewNoteFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNoteFirstInCache_MerchantNote>>,
	note: FragmentType<typeof addNoteFirstInCache_MerchantNote>,
	merchantId: string,
) {
	const rootQueryId = cache.identify({ __typename: "Merchant", id: merchantId });
	const noteData = useFragment(AddNoteFirstInCache_MerchantNoteFragmentDoc, note);
	cache.modify({
		id: rootQueryId,
		fields: {
			notes: (existingNotes) => {
				const ref = cache.writeFragment({
					id: cache.identify(noteData),
					fragment: MerchantNote_MerchantNoteFragment,
					fragmentName: "MerchantNote_MerchantNote",
					data: useFragment(MerchantNote_MerchantNoteFragmentDoc, noteData),
				});
				if (!existingNotes) {
					return [{ ref }];
				}
				return [{ ref }, ...existingNotes];
			},
		},
	});
}
