import { App } from "@/App.tsx";
import { AccountSettings } from "@/pages/accountSettings/AccountSettings";
import { AppLayout } from "@/pages/common/AppLayout.tsx";
import { DefaultSignedIn } from "@/pages/common/DefaultSignedIn.tsx";
import { ErrorBoundary } from "@/pages/common/ErrorBoundary";
import { Merchants } from "@/pages/merchants/Merchants.tsx";
import { MerchantsTable } from "@/pages/merchants/merchantsTable/MerchantsTable.tsx";
import { AssistedOnboarding } from "@/pages/merchants/onboarding/AssistedOnboarding.tsx";
import { Platforms } from "@/pages/platforms/Platforms.tsx";
import { Stages } from "@/pages/stages/Stages";
import { SubmitLead } from "@/pages/submitLead/SubmitLead";
import { Users } from "@/pages/users/Users";
import { UserForm } from "@/pages/users/userForm/UserForm";
import { UsersTable } from "@/pages/users/usersTable/UsersTable";
import { paths } from "@/paths.ts";
import * as Sentry from "@sentry/react";
import { createBrowserRouter } from "react-router-dom";

export function createRouter() {
	const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
	return sentryCreateBrowserRouter([
		{
			path: paths.submitLead,
			errorElement: <ErrorBoundary />,
			element: <SubmitLead />,
		},
		{
			element: <App />,
			errorElement: <ErrorBoundary />,
			children: [
				{
					path: "",
					element: <AppLayout />,
					children: [
						{
							path: paths.overview,
							element: <DefaultSignedIn />,
						},
						{
							path: paths.merchants,
							element: <Merchants />,
							children: [
								{
									path: paths.merchants,
									element: <MerchantsTable />,
								},
								{
									path: paths.assistedOnboarding,
									element: <AssistedOnboarding />,
								},
							],
						},
						{
							path: paths.stages,
							element: <Stages />,
						},
						{
							path: paths.platforms,
							element: <Platforms />,
						},
						{
							path: paths.reporting,
							element: <DefaultSignedIn />,
						},
						{
							path: paths.escalations,
							element: <DefaultSignedIn />,
						},
						{
							path: paths.accountSettings,
							element: <AccountSettings />,
						},
						{
							path: paths.users,
							element: <Users />,
							children: [
								{
									path: paths.users,
									element: <UsersTable />,
								},
								{
									path: paths.userForm,
									element: <UserForm />,
								},
							],
						},
					],
				},
			],
		},
	]);
}
