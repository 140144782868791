import { useAuth } from "@/auth/useAuth";
import { Stack } from "@mantine/core";

export function DefaultSignedIn() {
	const { user } = useAuth();
	return (
		<Stack gap="md">
			<div>Signed in as {user?.email}</div>
			{user && (
				<div>
					<div>First name: {user.firstName}</div>
					<div>Last name: {user.lastName}</div>
					<div>Email: {user.email}</div>
				</div>
			)}
		</Stack>
	);
}
