import { type FragmentType, gql, useFragment } from "@/__generated__";
import { MerchantNote_MerchantNoteFragmentDoc } from "@/__generated__/graphql";
import { getDisplayName } from "@/pages/common/utils";
import { Avatar, Group, Paper, Text, TypographyStylesProvider } from "@mantine/core";
import { formatDistanceToNow } from "date-fns/formatDistanceToNow";
import classes from "./MerchantNote.module.css";

export const MerchantNote_MerchantNoteFragment = gql(`
fragment MerchantNote_MerchantNote on MerchantNote {
    id
    message
    createdAt
    createdBy {
        ...getDisplayName_User
    }
}
`);

type MerchantNotesProps = {
	note: FragmentType<typeof MerchantNote_MerchantNoteFragment>;
};

export function MerchantNote({ note }: MerchantNotesProps) {
	const noteData = useFragment(MerchantNote_MerchantNoteFragmentDoc, note);
	const displayName = getDisplayName(noteData.createdBy);
	return (
		<Paper withBorder radius="md" className={classes.comment}>
			<Group>
				<Avatar name={displayName} color="initials" radius="xl" />
				<div>
					<Text fz="sm">{displayName}</Text>
					<Text fz="xs" c="dimmed">
						{formatDistanceToNow(noteData.createdAt, {
							addSuffix: true,
						})}
					</Text>
				</div>
			</Group>
			<TypographyStylesProvider className={classes.body}>
				<div className={classes.content}>{noteData.message}</div>
			</TypographyStylesProvider>
		</Paper>
	);
}
