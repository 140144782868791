import type { ColumnFiltersState, OnChangeFn, SortingState } from "@tanstack/react-table";
import {
	type MRT_ColumnDef,
	type MRT_ColumnFiltersState,
	type MRT_Localization,
	type MRT_Row,
	type MRT_RowData,
	type MRT_SortingState,
	type MRT_TableInstance,
	type MRT_TableState,
	useMantineReactTable,
} from "mantine-react-table";
import type { ReactNode } from "react";

type FastlaneTableOptions<TData extends MRT_RowData> = {
	data: TData[];
	columns: MRT_ColumnDef<TData>[];
	searchPlaceholder?: string;
	isLoading?: boolean;
	initialColumnVisibility?: Record<string, boolean>;
	onRowClick?: (row: MRT_Row<TData>) => void;
	enableTopToolbar?: boolean;
	manualSorting?: boolean;
	manualFiltering?: boolean;
	enableSorting?: boolean;
	enableColumnActions?: boolean;
	enableColumnFilters?: boolean;
	globalFilter?: string;
	columnFilters?: MRT_ColumnFiltersState;
	sorting?: MRT_SortingState;
	onSortingChange?: OnChangeFn<SortingState>;
	onGlobalFilterChange?: OnChangeFn<string>;
	onColumnFiltersChange?: OnChangeFn<ColumnFiltersState>;
	enableRowActions?: boolean;
	localization?: Partial<MRT_Localization>;
	renderRowActionMenuItems?: (props: {
		renderedRowIndex?: number;
		row: MRT_Row<TData>;
		table: MRT_TableInstance<TData>;
	}) => ReactNode;
};

export function useFastlaneTable<TData extends MRT_RowData>(tableOptions: FastlaneTableOptions<TData>) {
	const onRowClick = (row: MRT_Row<TData>) =>
		tableOptions.onRowClick
			? () => {
					if (tableOptions.onRowClick) {
						tableOptions.onRowClick(row);
					}
				}
			: undefined;
	const {
		enableTopToolbar = true,
		enableColumnActions = true,
		enableSorting = true,
		manualSorting = false,
		manualFiltering = false,
		enableColumnFilters = false,
		onColumnFiltersChange,
		onGlobalFilterChange,
		onSortingChange,
	} = tableOptions;
	return useMantineReactTable({
		data: tableOptions.data,
		columns: tableOptions.columns,
		enablePagination: false,
		enableTableFooter: false,
		enableBottomToolbar: false,
		manualFiltering,
		manualSorting,
		onColumnFiltersChange,
		onGlobalFilterChange,
		onSortingChange,
		enableColumnFilters,
		enableColumnActions,
		positionGlobalFilter: "left",
		enableTopToolbar,
		enableDensityToggle: false,
		enableSorting,
		enableFilterMatchHighlighting: false,
		layoutMode: "grid",
		mantineTableBodyRowProps: ({ row }) => ({
			onClick: onRowClick(row),
			style: {
				cursor: onRowClick(row) ? "pointer" : "default",
			},
		}),
		localization: tableOptions.localization,
		mantineSearchTextInputProps: {
			placeholder: tableOptions.searchPlaceholder,
		},
		mantinePaperProps: {
			withBorder: false,
			shadow: "none",
		},
		positionActionsColumn: "last",
		displayColumnDefOptions: {
			"mrt-row-actions": {
				header: "",
				size: 54,
				grow: false,
				mantineTableHeadCellProps: {
					align: "right",
				},
				mantineTableBodyCellProps: {
					align: "right",
				},
			},
		},
		initialState: {
			showColumnFilters: tableOptions.enableColumnFilters,
			showGlobalFilter: true,
			columnVisibility: tableOptions.initialColumnVisibility || {},
		},
		state: createState(tableOptions),
		enableRowActions: tableOptions.enableRowActions,
		renderRowActionMenuItems: tableOptions.renderRowActionMenuItems,
	});
}

function createState<TData extends MRT_RowData>(
	tableOptions: FastlaneTableOptions<TData>,
): Partial<MRT_TableState<TData>> {
	const state: Partial<MRT_TableState<TData>> = {
		showSkeletons: tableOptions.isLoading,
	};
	if (tableOptions.manualSorting) {
		state.sorting = tableOptions.sorting;
	}
	if (tableOptions.manualFiltering) {
		state.globalFilter = tableOptions.globalFilter;
		state.columnFilters = tableOptions.columnFilters;
	}

	return state;
}
