import { PasswordSignIn } from "@/pages/accountSettings/PasswordSignIn";
import { SsoProviders } from "@/pages/accountSettings/SsoProviders";
import { Group, Stack, Title } from "@mantine/core";

export function AccountSettings() {
	return (
		<Stack gap="xl">
			<Group justify="space-between" align="center">
				<Title order={1}>Account settings</Title>
			</Group>
			<SsoProviders />
			<PasswordSignIn />
		</Stack>
	);
}
