import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

export const AssistedOnboarding_GetData = gql(`
query AssistedOnboarding_GetData($merchantId: UUID!, $merchantIdProvided: Boolean!) {
    ...AssistedOnboarding_Query
}
`);

export function useAssistedOnboardingData(merchantId?: string) {
	const { data, loading, error } = useQuery(AssistedOnboarding_GetData, {
		errorPolicy: "all",
		variables: {
			// This is a hack since merchantId is required. The merchant object will is excluded
			// from the query so this generated uuid won't be used. It's necessary to make the query validation pass though.
			merchantId: merchantId || uuidv4(),
			merchantIdProvided: !!merchantId,
		},
	});
	const memoizedData = useMemo(() => data || undefined, [data]);
	return {
		data: memoizedData,
		loading,
		error,
	};
}
