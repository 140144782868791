import { getConfig } from "@/config";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

export function initializeSentry() {
	const config = getConfig();
	Sentry.init({
		dsn: "https://d3dc2b302ac8821555cba3dd59b30a74@o4508194169618432.ingest.de.sentry.io/4508194171912272",
		tracesSampleRate: 1.0,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect: useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
		],
		environment: config.environment,
		replaysSessionSampleRate: 1.0,
		replaysOnErrorSampleRate: 1.0,
	});
}
