import { type FragmentType, gql, useFragment } from "@/__generated__";
import { MerchantStage, Provisioning_MerchantFragmentDoc } from "@/__generated__/graphql";
import { MerchantStatusSelect } from "@/pages/merchants/onboarding/common/MerchantStatusSelector";
import { PROVISIONING_STATUSES } from "@/pages/merchants/onboarding/prospecting/constants";
import { useProvisioningForm } from "@/pages/merchants/onboarding/provisioning/useProvisioningForm";
import { Button, Flex, Grid, Stack, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const Provisioning_MerchantFragment = gql(`
fragment Provisioning_Merchant on Merchant {
	id
	stage
	status
}
`);

type ProvisioningProps = {
	onClose: () => void;
	setDirty: (dirty: boolean) => void;
	merchant: FragmentType<typeof Provisioning_MerchantFragment>;
};

export function Provisioning({ setDirty, merchant, onClose }: ProvisioningProps) {
	const merchantData = useFragment(Provisioning_MerchantFragmentDoc, merchant);
	const { form, onContinue, onSave, submittingContinue, submittingSave } = useProvisioningForm(setDirty, merchantData);
	return (
		<form style={{ display: "contents" }}>
			<Stack gap="xl" h="100%" justify="space-between">
				<Stack gap="lg">
					<Title order={1}>Provisioning</Title>
					<Grid>
						<Grid.Col span={{ xs: 12, sm: 6 }}>
							<MerchantStatusSelect
								selectableStatuses={PROVISIONING_STATUSES}
								{...form.getInputProps("status")}
								label="Status"
								key={form.key("status")}
								disabled={merchantData?.stage !== MerchantStage.PROVISIONING}
							/>
						</Grid.Col>
					</Grid>
				</Stack>
				<Flex direction="row" justify="end" gap="sm">
					<Button type="button" variant="subtle" color="gray" onClick={onClose}>
						Cancel
					</Button>
					<Button loading={submittingSave} type="submit" onClick={onSave}>
						Save
					</Button>
					<Button
						loading={submittingContinue}
						variant="light"
						type="submit"
						onClick={onContinue}
						disabled={merchantData.stage !== MerchantStage.PROVISIONING}
						rightSection={<IconArrowRight />}
					>
						Continue
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
