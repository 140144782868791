import type { BusinessEligibility_MerchantFragment, MerchantStatus } from "@/__generated__/graphql";
import { useSaveBusinessEligibilityData } from "@/pages/merchants/onboarding/businessEligibility/useSaveBusinessEligibilityData";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

type BusinessEligibilityForm = {
	status: MerchantStatus;
};

export function useBusinessEligibilityForm(
	setDirty: (dirty: boolean) => void,
	merchant: BusinessEligibility_MerchantFragment,
) {
	const form = useForm<BusinessEligibilityForm>({
		mode: "uncontrolled",
		initialValues: {
			status: merchant.status,
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {},
	});
	const [submittingSave, setSubmittingSave] = useState(false);
	const [submittingContinue, setSubmittingContinue] = useState(false);
	const { saveBusinessEligibilityData, error } = useSaveBusinessEligibilityData();

	const onSubmit = async (proceed: boolean) => {
		if (form.validate().hasErrors) {
			return;
		}
		const values = form.getValues();
		try {
			const { data } = await saveBusinessEligibilityData({
				variables: {
					input: {
						merchantId: merchant.id,
						newStatus: values.status,
						proceedToNextStage: proceed,
					},
				},
			});
			if (data?.merchant?.saveBusinessEligibilityData?.success) {
				notifications.show({
					title: "Business eligibility data saved",
					message: "The provided business eligibility data has been successfully saved.",
				});
				form.resetDirty();
				setDirty(false);
			} else {
				notifications.show({
					title: `Failed to save business eligibility data: ${data?.merchant?.saveBusinessEligibilityData?.error?.code}`,
					message: data?.merchant?.saveBusinessEligibilityData?.error?.message,
					color: "red",
				});
			}
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to save business eligibility data",
				message: "Something went wrong while saving business eligibility data.",
				color: "red",
			});
		}
	};
	const onSave = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingSave(true);
		await onSubmit(false);
		setSubmittingSave(false);
	};
	const onContinue = async (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();
		setSubmittingContinue(true);
		await onSubmit(true);
		setSubmittingContinue(false);
	};

	return {
		form,
		onSave,
		onContinue,
		submittingSave,
		submittingContinue,
		error,
	};
}
