import { GoogleIcon } from "@/components/GoogleIcon";
import { MicrosoftIcon } from "@/components/MicrosoftIcon";
import { Button, type ButtonProps } from "@mantine/core";

interface SsoButtonProps extends ButtonProps {
	provider: "google" | "microsoft";
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export function SsoButton({ provider, children, ...props }: SsoButtonProps) {
	return (
		<Button variant="outline" leftSection={provider === "google" ? <GoogleIcon /> : <MicrosoftIcon />} {...props}>
			{children}
		</Button>
	);
}
