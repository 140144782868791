import { PaymentMethod, PaymentVolumeEstimate } from "@/__generated__/graphql";

export const PAYMENT_VOLUME_ESTIMATES = [
	{ value: PaymentVolumeEstimate.UNDER_10_000, label: "Under $10,000" },
	{
		value: PaymentVolumeEstimate.FROM_10_000_TO_50_000,
		label: "$10,000 - $50,000",
	},
	{
		value: PaymentVolumeEstimate.FROM_50_000_TO_100_000,
		label: "$50,000 - $100,000",
	},
	{ value: PaymentVolumeEstimate.OVER_100_000, label: "Over $100,000" },
];

export const PAYMENT_METHODS = [
	{ value: PaymentMethod.BANK_TRANSFERS, label: "Bank transfers" },
	{ value: PaymentMethod.CREDIT_DEBIT_CARDS, label: "Credit/Debit cards" },
	{
		value: PaymentMethod.DIGITAL_WALLETS,
		label: "Digital wallets (PayPal, Apple Pay, etc.)",
	},
];

export const INDUSTRIES = [
	{ value: "AGRICULTURE", label: "Agriculture" },
	{ value: "FISHING", label: "Fishing" },
	{ value: "HORTICULTURE", label: "Horticulture" },
	{ value: "TOBACCO", label: "Tobacco" },
	{ value: "WOOD", label: "Wood" },
	{ value: "MANUFACTURING", label: "Manufacturing" },
	{ value: "AEROSPACE", label: "Aerospace" },
	{ value: "AUTOMOTIVE", label: "Automotive" },
	{ value: "CHEMICAL", label: "Chemical" },
	{ value: "PHARMACEUTICAL", label: "Pharmaceutical" },
	{ value: "CONSTRUCTION", label: "Construction" },
	{ value: "DEFENSE", label: "Defense" },
	{ value: "ARMS", label: "Arms" },
	{ value: "ELECTRIC_POWER", label: "Electric power" },
	{ value: "ELECTRONICS", label: "Electronics" },
	{ value: "COMPUTER", label: "Computer" },
	{ value: "SEMICONDUCTOR", label: "Semiconductor" },
	{ value: "ENERGY", label: "Energy" },
	{ value: "FOOD", label: "Food" },
	{ value: "DRINK", label: "Drink" },
	{ value: "INDUSTRIAL_ROBOT", label: "Industrial robot" },
	{ value: "LOW_TECHNOLOGY", label: "Low technology" },
	{ value: "MEAT", label: "Meat" },
	{ value: "MEAT_PACKING", label: "Meat packing" },
	{ value: "MINING", label: "Mining" },
	{ value: "OIL_AND_GAS", label: "Oil and gas" },
	{ value: "PETROLEUM", label: "Petroleum" },
	{ value: "OIL_SHALE", label: "Oil shale" },
	{ value: "PULP_AND_PAPER", label: "Pulp and paper" },
	{ value: "STEEL", label: "Steel" },
	{ value: "SHIPBUILDING", label: "Shipbuilding" },
	{ value: "TEXTILE", label: "Textile" },
	{ value: "WATER", label: "Water" },
	{ value: "BROADCASTING", label: "Broadcasting" },
	{ value: "RADIO", label: "Radio" },
	{ value: "TELEVISION", label: "Television" },
	{ value: "CREATIVE", label: "Creative" },
	{ value: "ADVERTISING", label: "Advertising" },
	{ value: "FASHION", label: "Fashion" },
	{ value: "FLORAL", label: "Floral" },
	{ value: "CULTURAL", label: "Cultural" },
	{ value: "CULTURE", label: "Culture" },
	{ value: "EDUCATION", label: "Education" },
	{ value: "MUSIC", label: "Music" },
	{ value: "FILM", label: "Film" },
	{ value: "GAMBLING", label: "Gambling" },
	{ value: "VIDEO_GAME", label: "Video game" },
	{ value: "FINANCIAL SERVICES", label: "Financial services" },
	{ value: "INSURANCE", label: "Insurance" },
	{ value: "HEALTHCARE", label: "Healthcare" },
	{ value: "HOSPITALITY", label: "Hospitality" },
	{ value: "INFORMATION", label: "Information" },
	{ value: "LEISURE", label: "Leisure" },
	{ value: "MASS MEDIA", label: "Mass media" },
	{ value: "INTERNET", label: "Internet" },
	{ value: "NEWS MEDIA", label: "News media" },
	{ value: "PUBLISHING", label: "Publishing" },
	{ value: "ENTERTAINMENT", label: "Entertainment" },
	{ value: "PROFESSIONAL_SERVICES", label: "Professional services" },
	{ value: "REAL ESTATE", label: "Real estate" },
	{ value: "RETAIL", label: "Retail" },
	{ value: "SPORT", label: "Sport" },
	{ value: "TECHNOLOGY", label: "Technology" },
	{ value: "SOFTWARE", label: "Software" },
	{ value: "TELECOMMUNICATIONS", label: "Telecommunications" },
	{ value: "TRANSPORTATION", label: "Transport" },
];

export const INDUSTRY_VALUES = INDUSTRIES.map((industry) => industry.value);
