import { MerchantStage, type StagesTable_StageStatisticsFragment } from "@/__generated__/graphql";
import { Text } from "@mantine/core";
import type { MRT_Cell } from "mantine-react-table";

type RevenueCellProps = {
	cell: MRT_Cell<StagesTable_StageStatisticsFragment, string | null>;
};
export function RevenueCell({ cell }: RevenueCellProps) {
	if (cell.row.original.stage === MerchantStage.LIVE && ["projectedToClose", "lostRevenue"].includes(cell.column.id)) {
		return <span>N/A</span>;
	}
	const amount = cell.getValue();
	if (amount === undefined || amount === null) {
		return (
			<Text c="dimmed" size="sm" fs="italic">
				No data
			</Text>
		);
	}

	const currencyFormatter = new Intl.NumberFormat(undefined, {
		style: "currency",
		currency: "AUD",
	});
	return <span>{currencyFormatter.format(Number.parseFloat(amount))}</span>;
}
