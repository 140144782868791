import { type UserForm_UserFragment, UserType } from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { useSaveUserForm } from "@/pages/users/userForm/useSaveUserForm";
import { paths } from "@/paths";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";
import { generatePath, useNavigate } from "react-router";

type UserFormType = {
	firstName: string;
	lastName: string;
	email: string;
	userType: UserType;
	hasWriteAccess: boolean;
	merchantId?: string;
	platformId?: string;
};
export function useUserForm(user?: UserForm_UserFragment | null) {
	const { user: loggedInUser } = useAuth();
	const navigate = useNavigate();
	const [isDirty, setDirty] = useState(false);
	const initialUserType =
		user?.type ||
		(loggedInUser?.type === UserType.FASTLANEIQ ? UserType.MERCHANT : loggedInUser?.type) ||
		UserType.MERCHANT;

	const initialValues = {
		firstName: user?.firstName || "",
		lastName: user?.lastName || "",
		email: user?.email || "",
		userType: initialUserType,
		hasWriteAccess: user ? user.hasWriteAccess : false,
		platformId: user?.platform?.id || loggedInUser?.platform?.id,
		merchantId: user?.merchant?.id || loggedInUser?.merchant?.id,
	};
	const { createUser, updateUser, loading } = useSaveUserForm();
	const [userType, setUserType] = useState<UserType>(initialValues.userType);
	const form = useForm<UserFormType>({
		mode: "uncontrolled",
		initialValues,
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {
			firstName: isNotEmpty("First name is required"),
			lastName: isNotEmpty("Last name is required"),
			email: (value, values) =>
				isEmail("Must be a valid email")(value) ||
				(values.userType === UserType.FASTLANEIQ && !values.email?.endsWith("@fastlaneiq.com"))
					? "Fastlane IQ users must have an @fastlaneiq.com email"
					: null,
			platformId: (value, values) =>
				values.userType === UserType.PLATFORM && !value ? "Please select a platform" : null,
			merchantId: (value, values) =>
				values.userType === UserType.MERCHANT && !value ? "Please select a merchant" : null,
		},
	});
	form.watch("userType", ({ value }) => setUserType(value));

	const onSubmit = async (values: UserFormType) => {
		if (user) {
			await submitUpdate(values);
		} else {
			await submitCreate(values);
		}
	};

	const submitCreate = async (values: UserFormType) => {
		try {
			const { data } = await createUser({
				variables: {
					input: {
						email: values.email,
						firstName: values.firstName,
						lastName: values.lastName,
						type: values.userType,
						hasWriteAccess: values.hasWriteAccess,
						platformId: values.platformId,
						merchantId: values.merchantId,
					},
				},
			});
			if (data?.user.create.success) {
				notifications.show({
					title: "User created",
					message: "User created successfully",
				});
				form.resetDirty();
				setDirty(false);
				navigate(generatePath(paths.users));
			} else {
				notifications.show({
					title: "Failed to create user",
					message: `Failed with error: ${data?.user.create.error?.code}`,
					color: "red",
				});
			}
		} catch (e) {
			console.error(e);
			notifications.show({
				title: "Failed to create user",
				message: `Failed with error: ${e}`,
				color: "red",
			});
		}
	};

	const submitUpdate = async (values: UserFormType) => {
		try {
			const { data } = await updateUser({
				variables: {
					input: {
						userId: user?.id,
						firstName: values.firstName,
						lastName: values.lastName,
						hasWriteAccess: values.hasWriteAccess,
					},
				},
			});
			if (data?.user.update.success) {
				notifications.show({
					title: "User updated",
					message: "User updated successfully",
				});
				form.resetDirty();
				setDirty(false);
				navigate(generatePath(paths.users));
			} else {
				notifications.show({
					title: "Failed to update user",
					message: `Failed with error: ${data?.user.update.error?.code}`,
					color: "red",
				});
			}
		} catch (e) {
			console.error(e);
			notifications.show({
				title: "Failed to update user",
				message: `Failed with error: ${e}`,
				color: "red",
			});
		}
	};
	return {
		form,
		onSubmit: form.onSubmit(onSubmit),
		isDirty,
		loading,
		userType,
	};
}
