import type { MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { snakeToHumanReadable } from "@/pages/common/utils";
import { getStatusIndicatorColor } from "@/pages/merchants/merchantsTable/getStatusIndicatorColor";
import { Box, ColorSwatch, Group } from "@mantine/core";
import type { MRT_Cell } from "mantine-react-table";
import type { ReactNode } from "react";

type MerchantStatusCellProps = {
	renderedCellValue: ReactNode | number | string;
	cell: MRT_Cell<MerchantsTable_MerchantFragment, string>;
};

export function MerchantStatusCell({ cell }: MerchantStatusCellProps) {
	const value = cell.getValue();

	return (
		<Group gap="xs" style={{ flexWrap: "nowrap" }}>
			<ColorSwatch withShadow={false} color={getStatusIndicatorColor(cell.row.original.status)} size={8} />
			<Box>{snakeToHumanReadable(value)}</Box>
		</Group>
	);
}
