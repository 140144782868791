import { paths } from "@/paths";
import { Button, Container, Group, Text, Title } from "@mantine/core";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { Link, isRouteErrorResponse, useRouteError } from "react-router-dom";
import classes from "./ErrorBoundary.module.css";

export function ErrorBoundary() {
	const error = useRouteError();
	useEffect(() => {
		const isRouteNotFoundError = isRouteErrorResponse(error) && error.status === 404;
		if (!isRouteNotFoundError) {
			Sentry.captureException(error);
		}
	}, [error]);

	let code = "500";
	let title = "An unexpected error occured";
	let description =
		"Unfortunately, this is only a 500 page. Something went wrong on our end, we apologize for the inconvenience. We have been notified of the issue and will look into it.";

	if (isRouteErrorResponse(error)) {
		code = String(error.status);
		if (error.status === 404) {
			code = "404";
			title = "You have found a secret place.";
			description =
				"Unfortunately, this is only a 404 page. You may have mistyped the address, or the page has been moved to another URL.";
		}
	}
	return (
		<Container className={classes.root}>
			<div className={classes.label}>{code}</div>
			<Title className={classes.title}>{title}</Title>
			<Text c="dimmed" size="lg" ta="center" className={classes.description}>
				{description}
			</Text>
			<Group justify="center">
				<Button variant="subtle" size="md" component={Link} to={paths.overview}>
					Take me back to home page
				</Button>
			</Group>
		</Container>
	);
}
