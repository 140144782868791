import { ToggleSsoProvider } from "@/pages/accountSettings/ToggleSsoProvider";
import { Stack, Title } from "@mantine/core";

export function SsoProviders() {
	return (
		<Stack>
			<Title order={4}>SSO sign in</Title>
			<Stack maw={400}>
				<ToggleSsoProvider provider="google" />
				<ToggleSsoProvider provider="microsoft" />
			</Stack>
		</Stack>
	);
}
