import { SsoButton } from "@/components/SsoButton";
import type { FirebaseError } from "firebase/app";
import { signInWithPopup } from "firebase/auth";
import { useState } from "react";
import { useAuth } from "../../auth/useAuth";
import { useFirebaseAuthProvider } from "../../auth/useFirebaseAuthProvider";

export type SSOSignInProps = {
	provider: "google" | "microsoft";
	onError: (error: FirebaseError) => void;
};

export function SsoSignIn({ provider, onError }: SSOSignInProps) {
	const [isLoading, setIsLoading] = useState(false);
	const { firebaseAuth } = useAuth();
	const buttonText = provider === "google" ? "Sign in with Google" : "Sign in with Microsoft";

	const handleClick = () => {
		const authProvider = useFirebaseAuthProvider(provider);
		setIsLoading(true);
		signInWithPopup(firebaseAuth, authProvider).catch((error: FirebaseError) => {
			setIsLoading(false);
			onError(error);
		});
	};

	return (
		<SsoButton loading={isLoading} onClick={handleClick} fullWidth provider={provider} size="md" color="gray">
			{buttonText}
		</SsoButton>
	);
}
