import { Button, Flex, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useEffect } from "react";

type usePreventClosingDirtyFormReturn = {
	protectedClose: () => void;
	confirm: JSX.Element;
};

export function usePreventClosingDirtyForm(isDirty: boolean, onClose?: () => void): usePreventClosingDirtyFormReturn {
	const [confirmOpen, { open: openConfirm, close: closeConfirm }] = useDisclosure(false);
	useEffect(() => {
		if (isDirty) {
			const prevWindowOnBeforeUnload = window.onbeforeunload;
			window.onbeforeunload = () => "You have unsaved changes, are you sure you want to leave?";
			return () => {
				window.onbeforeunload = prevWindowOnBeforeUnload;
			};
		}
	}, [isDirty]);
	const protectedClose = () => {
		if (isDirty) {
			openConfirm();
			return;
		}
		if (onClose) {
			onClose();
		}
	};

	return {
		protectedClose,
		confirm: (
			<Modal opened={confirmOpen} onClose={closeConfirm} title="Unsaved changes" size="lg" centered>
				<div>You have unsaved changes, are you sure you want to leave?</div>
				<Flex direction="row-reverse" gap="sm" mt="sm">
					<Button color="red" onClick={onClose}>
						Discard changes
					</Button>
					<Button type="button" variant="subtle" color="gray" onClick={closeConfirm}>
						Cancel
					</Button>
				</Flex>
			</Modal>
		),
	};
}
