const COLOR_BY_MERCHANT_STATUS: Record<string, string> = {
	LEAD_GENERATED: "gray",
	INITIAL_CONTACT_MADE: "yellow",
	INFORMATION_RECEIVED: "yellow",
	INFORMATION_REQUESTED: "yellow",
	DISQUALIFIED: "red",
	QUALIFIED_LEAD: "fastlane-green",
	ELIGIBILITY_CHECK_INITIATED: "yellow",
	AWAITING_BUSINESS_INFORMATION: "yellow",
	UNDER_REVIEW: "yellow",
	ELIGIBLE: "fastlane-green",
	NOT_ELIGIBLE: "red",
	ADDITIONAL_INFORMATION_REQUIRED: "yellow",
	KYC_AML_CHECK_INITIATED: "yellow",
	DOCUMENT_COLLECTION_IN_PROGRESS: "yellow",
	AWAITING_ADDITIONAL_DOCUMENTS: "yellow",
	BACKGROUND_CHECK_IN_PROGRESS: "yellow",
	MANUAL_REVIEW_REQUIRED: "yellow",
	VERIFICATION_PASSED: "fastlane-green",
	VERIFICATION_FAILED: "red",
	CONTRACT_SENT: "yellow",
	AWAITING_SIGNATURE: "yellow",
	CONTRACT_RECEIVED: "yellow",
	TERMS_NEGOTIATION: "yellow",
	CONTRACT_APPROVED: "fastlane-green",
	CONTRACT_DECLINED: "red",
	ACCOUNT_SETUP_INITIATED: "yellow",
	INTEGRATION_IN_PROGRESS: "yellow",
	TESTING_PHASE: "yellow",
	AWAITING_API_CREDENTIALS: "yellow",
	TRAINING_REQUIRED: "yellow",
	SETUP_COMPLETE: "fastlane-green",
	SOFT_LAUNCH: "yellow",
	MONITORING_PERIOD: "yellow",
	FULLY_OPERATIONAL: "fastlane-green",
	UNDER_PERFORMANCE_REVIEW: "fastlane-green",
	TEMPORARILY_SUSPENDED: "gray",
	ACCOUNT_CLOSED: "gray",
	VOLUNTARY_CLOSURE: "gray",
	INVOLUNTARY_CLOSURE: "gray",
	ACCOUNT_DEACTIVATED: "gray",
	FINAL_SETTLEMENT_PROCESSED: "gray",
	CLOSURE_CONFIRMED: "gray",
};
export function getStatusIndicatorColor(status: string) {
	const color = COLOR_BY_MERCHANT_STATUS[status];
	if (!color) {
		return "var(--mantine-color-blue-4)";
	}
	return `var(--mantine-color-${color}-6)`;
}
