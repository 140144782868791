import { MerchantStage, type StagesTable_StageStatisticsFragment } from "@/__generated__/graphql";

const CUSTOM_ORDER = [
	MerchantStage.PROSPECTING,
	MerchantStage.BUSINESS_ELIGIBILITY,
	MerchantStage.BUSINESS_VERIFICATION,
	MerchantStage.CONTRACTING,
	MerchantStage.PROVISIONING,
	MerchantStage.LIVE,
	MerchantStage.CLOSED,
];
const ORDER_MAP = new Map(CUSTOM_ORDER.map((name, index) => [name, index]));

export function sortStageStatistics(stats: StagesTable_StageStatisticsFragment[]) {
	return stats.sort((a, b) => {
		const indexA = ORDER_MAP.get(a.stage) ?? Number.MAX_SAFE_INTEGER;
		const indexB = ORDER_MAP.get(b.stage) ?? Number.MAX_SAFE_INTEGER;
		return indexA - indexB;
	});
}
