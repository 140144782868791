import { UserType } from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { CreateNewPlatform } from "@/pages/platforms/createNewPlatform/CreateNewPlatform";
import { PlatformsTable } from "@/pages/platforms/platformsTable/PlatformsTable";
import { Button, Group, Stack, Title } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconPlus } from "@tabler/icons-react";

export function Platforms() {
	const [opened, { open, close }] = useDisclosure(false);
	const { user } = useAuth();
	if (opened) {
		return <CreateNewPlatform onClose={close} />;
	}
	return (
		<Stack gap="xl">
			<Group justify="space-between" align="center">
				<Title order={1}>Platforms</Title>
				{user?.type === UserType.FASTLANEIQ && user?.hasWriteAccess && (
					<Button leftSection={<IconPlus />} onClick={open}>
						Create new platform
					</Button>
				)}
			</Group>
			<PlatformsTable />
		</Stack>
	);
}
