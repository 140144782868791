import { gql } from "@/__generated__";
import { useQuery } from "@apollo/client";
import { useMemo } from "react";

const UsersTable_GetData = gql(`
query UsersTable_GetData {
	...UsersTable_Query
}
`);

export function useUsersTableData() {
	const { data, loading, error } = useQuery(UsersTable_GetData, {
		errorPolicy: "all",
	});
	const memoizedData = useMemo(() => data || undefined, [data]);
	return {
		data: memoizedData,
		loading,
		error,
	};
}
