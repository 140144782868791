import { type FragmentType, gql, useFragment } from "@/__generated__";
import type { ApolloCache } from "@apollo/client";

const addNewPlatformFirstInCache_Platform = gql(`
fragment addNewPlatformFirstInCache_Platform on Platform {
	...PlatformsTable_Platform
	...SelectPlatform_Platform
}
`);

export function addNewPlatformFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNewPlatformFirstInCache_Platform>>,
	platform: FragmentType<typeof addNewPlatformFirstInCache_Platform>,
) {
	const rootQueryId = cache.identify({ __typename: "Query" });
	const platformData = useFragment(addNewPlatformFirstInCache_Platform, platform);
	cache.modify({
		id: rootQueryId,
		fields: {
			platforms(existing) {
				if (!existing) {
					return { edges: [{ node: platformData }] };
				}
				return {
					edges: [{ node: platformData }, ...existing.edges],
				};
			},
		},
	});
}
