import { type FragmentType, gql, useFragment } from "@/__generated__";
import { OnboardingStep_MerchantFragmentDoc } from "@/__generated__/graphql";
import { MerchantNotes } from "@/pages/merchants/onboarding/merchantNotes/MerchantNotes";
import { Divider, Grid } from "@mantine/core";
import classes from "./OnboardingStep.module.css";

const OnboardingStep_MerchantFragment = gql(`
fragment OnboardingStep_Merchant on Merchant {
	id
	...MerchantNotes_Merchant
}
`);

type OnboardingStepProps = {
	children: React.ReactNode;
	merchant?: FragmentType<typeof OnboardingStep_MerchantFragment>;
	setDirty: (dirty: boolean) => void;
};

export function OnboardingStep({ children, merchant, setDirty }: OnboardingStepProps) {
	const merchantData = useFragment(OnboardingStep_MerchantFragmentDoc, merchant);
	return (
		<Grid gutter="xl" className={classes.gridRoot} classNames={{ inner: classes.gridInner }}>
			<Grid.Col span={{ base: 12, md: 8 }} className={classes.column}>
				{children}
			</Grid.Col>
			<Grid.Col span={{ base: 12, md: 4 }} className={classes.column}>
				<Divider orientation="vertical" className={classes.divider} />
				<MerchantNotes merchant={merchantData} setDirty={setDirty} />
			</Grid.Col>
		</Grid>
	);
}
