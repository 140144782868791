import { gql } from "@/__generated__";
import { evictPlatformStageStatistics } from "@/pages/merchants/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_CONTRACTING_DATA_MUTATION = gql(`
mutation saveContractingData($input: SaveContractingDataInput!) {
	merchant {
		saveContractingData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...Contracting_Merchant
				...evictPlatformStageStatistics_Merchant
			}
		}
	}
}
`);
export function useSaveContractingData() {
	const [saveContractingData, { loading, error }] = useMutation(SAVE_CONTRACTING_DATA_MUTATION, {
		update(cache, { data }) {
			const saveContractingData = data?.merchant.saveContractingData;
			if (saveContractingData?.success && saveContractingData?.merchant) {
				evictPlatformStageStatistics(cache, saveContractingData.merchant);
			}
		},
	});
	return {
		saveContractingData,
		loading,
		error,
	};
}
