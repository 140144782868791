import { Button, Flex, Modal, Text, TextInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";

type useConfirmDestructiveActionReturn<T> = {
	protectedAction: (data: T) => void;
	confirm: JSX.Element;
};

type useConfirmDesctructiveActionOptions<T> = {
	action: (data: T) => void;
	confirmText?: (data: T) => string;
	message?: string;
	buttonText?: string;
};

export function useConfirmDesctructiveAction<T>({
	action,
	confirmText,
	message = "The action you are about to perform cannot be undone. Are you sure you want to continue?",
	buttonText = "Continue",
}: useConfirmDesctructiveActionOptions<T>): useConfirmDestructiveActionReturn<T> {
	const [confirmOpen, { open: openConfirm, close: closeConfirm }] = useDisclosure(false);
	const [userConfirmText, setUserConfirmText] = useState("");
	const [data, setData] = useState<T | null>(null);
	const protectedAction = (data: T) => {
		setData(data);
		openConfirm();
	};
	const exptectedConfirmText = confirmText && data ? confirmText(data) : "delete";
	const performAction = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if (userConfirmText === exptectedConfirmText && data) {
			action(data);
			closeConfirm();
		}
	};

	return {
		protectedAction,
		confirm: (
			<Modal opened={confirmOpen} onClose={closeConfirm} title="Are you sure?" size="lg" centered>
				<Text>{message}</Text>
				<form onSubmit={performAction}>
					<TextInput
						description={
							<span>
								Type <b>{exptectedConfirmText}</b> to continue.
							</span>
						}
						value={userConfirmText}
						onChange={(e) => setUserConfirmText(e.currentTarget.value)}
					/>
					<Flex direction="row-reverse" gap="sm" mt="sm">
						<Button color="red" type="submit" disabled={userConfirmText !== exptectedConfirmText}>
							{buttonText}
						</Button>
						<Button type="button" variant="subtle" color="gray" onClick={closeConfirm}>
							Cancel
						</Button>
					</Flex>
				</form>
			</Modal>
		),
	};
}
