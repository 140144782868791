import { type FragmentType, gql, useFragment } from "@/__generated__";
import { Live_MerchantFragmentDoc } from "@/__generated__/graphql";

const Live_MerchantFragment = gql(`
fragment Live_Merchant on Merchant {
	id
	stage
	status
}
`);

type LiveProps = {
	merchant: FragmentType<typeof Live_MerchantFragment>;
};

export function Live({ merchant }: LiveProps) {
	const merchantData = useFragment(Live_MerchantFragmentDoc, merchant);
	return (
		<div>
			<div>Merchant is live!</div>
			<div>Status: {merchantData.status}</div>
		</div>
	);
}
