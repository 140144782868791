import { gql } from "@/__generated__";
import { evictPlatformStageStatistics } from "@/pages/merchants/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_BUSINESS_ELIGIBILITY_DATA_MUTATION = gql(`
mutation saveBusinessEligibilityData($input: SaveBusinessEligibilityDataInput!) {
	merchant {
		saveBusinessEligibilityData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...BusinessEligibility_Merchant
				...evictPlatformStageStatistics_Merchant
			}
		}
	}
}
`);
export function useSaveBusinessEligibilityData() {
	const [saveBusinessEligibilityData, { loading, error }] = useMutation(SAVE_BUSINESS_ELIGIBILITY_DATA_MUTATION, {
		update(cache, { data }) {
			const saveBusinessEligibilityData = data?.merchant.saveBusinessEligibilityData;
			if (saveBusinessEligibilityData?.success && saveBusinessEligibilityData?.merchant) {
				evictPlatformStageStatistics(cache, saveBusinessEligibilityData.merchant);
			}
		},
	});
	return {
		saveBusinessEligibilityData,
		loading,
		error,
	};
}
