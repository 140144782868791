import { gql, useFragment } from "@/__generated__";
import {
	StagesTable_QueryFragmentDoc,
	type StagesTable_StageStatisticsFragment,
	StagesTable_StageStatisticsFragmentDoc,
	UserType,
} from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { SelectPlaform } from "@/components/SelectPlaform";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { parseParamsToObject, snakeToHumanReadable } from "@/pages/common/utils";
import { AverageDaysInStageCell } from "@/pages/stages/stagesTable/AverageDaysInStageCell";
import { ConversionRateCell } from "@/pages/stages/stagesTable/ConversionRateCell";
import { RevenueCell } from "@/pages/stages/stagesTable/RevenueCell";
import { useStagesTableData } from "@/pages/stages/stagesTable/useStagesTableData";
import { sortStageStatistics } from "@/pages/stages/stagesTable/utils";
import { Alert, Box } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router";

gql(`
fragment StagesTable_StageStatistics on StageStatistics {
    stage
    averageDaysInStage
    conversionRate
    lostRevenue
    merchantsInStage
    projectedToClose
    revenueInStage
    totalRevenueAdded
}
`);

gql(`
fragment StagesTable_Query on Query {
    platform(platformId: $platformId) @include(if: $platformIdProvided) {
        id
        lifecycleStatistics {
            stages {
                ...StagesTable_StageStatistics
            }
        }
    }
    ...SelectPlaform_Query
}
`);

export function StagesTable() {
	const location = useLocation();
	const navigate = useNavigate();
	const { platformId: selectedPlatformId } = parseParamsToObject(location.hash);
	const { user } = useAuth();
	const setSelectedPlatformId = (value?: string) => {
		if (!value) {
			navigate("#");
		} else {
			navigate(`#platformId=${value}`);
		}
	};
	const platformId: string | undefined = user?.type === UserType.PLATFORM ? user?.platform?.id : selectedPlatformId;
	const { error, data, loading } = useStagesTableData(platformId);
	const queryData = useFragment(StagesTable_QueryFragmentDoc, data);
	const stagesData =
		queryData?.platform?.lifecycleStatistics?.stages?.map((stage) =>
			useFragment(StagesTable_StageStatisticsFragmentDoc, stage),
		) || [];

	const sortedStages = sortStageStatistics(stagesData);

	const columns: Array<MRT_ColumnDef<StagesTable_StageStatisticsFragment>> = useMemo(
		() => [
			{
				accessorFn: (row) => snakeToHumanReadable(row.stage),
				header: "Stage",
			},
			{
				accessorKey: "merchantsInStage",
				header: "Merchants in stage",
			},
			{
				accessorKey: "revenueInStage",
				header: "Revenue in stage",
				Cell: RevenueCell,
			},
			{
				accessorKey: "projectedToClose",
				header: "Projected to close",
				Cell: RevenueCell,
			},
			{
				accessorKey: "totalRevenueAdded",
				header: "Total revenue added",
				Cell: RevenueCell,
			},
			{
				accessorKey: "lostRevenue",
				header: "Lost revenue",
				Cell: RevenueCell,
			},
			{
				accessorKey: "conversionRate",
				header: "Conversion rate",
				Cell: ConversionRateCell,
			},
			{
				accessorKey: "averageDaysInStage",
				header: "Average days in stage",
				Cell: AverageDaysInStageCell,
			},
		],
		[],
	);

	const table = useFastlaneTable<StagesTable_StageStatisticsFragment>({
		data: sortedStages,
		columns,
		isLoading: loading && !!platformId,
		enableTopToolbar: false,
		enableSorting: false,
		enableColumnActions: false,
		localization: {
			noRecordsToDisplay: "Select a platform to see stage breakdown",
		},
	});
	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<Box maw={300}>
				{user?.type === UserType.FASTLANEIQ && (
					<SelectPlaform
						value={platformId}
						platformData={queryData}
						onChange={(value) => setSelectedPlatformId(value || undefined)}
					/>
				)}
			</Box>
			<MantineReactTable table={table} />
		</div>
	);
}
