import { useAuth } from "@/auth/useAuth";
import { useNavbarItems } from "@/pages/common/useNavbarItems";
import { paths } from "@/paths";
import { AppShell, Avatar, Group, NavLink as MantineNavLink, Title } from "@mantine/core";
import { useHover } from "@mantine/hooks";
import { IconLogout, IconUsers } from "@tabler/icons-react";
import { clsx } from "clsx";
import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";

export function Navbar() {
	const { handleSignOut, displayName } = useAuth();
	const { hovered: open, ref } = useHover();
	const { navbarItems } = useNavbarItems();

	const links = navbarItems.map((item) => (
		<MantineNavLink
			className={classes.link}
			component={NavLink}
			to={item.link}
			key={item.label}
			label={open ? item.label : undefined}
			leftSection={<item.icon className={classes.linkIcon} stroke={1.5} />}
		/>
	));

	return (
		<AppShell.Navbar className={clsx(classes.navbar, open && classes.open)} ref={ref}>
			<div className={classes.navbarMain}>
				<Group className={classes.header}>
					<Title order={2} className={classes.title}>
						{open ? "fastlaneIQ" : "f"}
					</Title>
				</Group>
				{links}
			</div>

			<div className={classes.footer}>
				<MantineNavLink
					className={classes.link}
					component={NavLink}
					to={paths.accountSettings}
					leftSection={<Avatar className={classes.linkIcon} name={displayName} size={25} color="initials" />}
					label={open ? <span>{displayName}</span> : undefined}
				/>
				<MantineNavLink
					className={classes.link}
					component={NavLink}
					to={paths.users}
					label={open ? "Users" : undefined}
					leftSection={<IconUsers className={classes.linkIcon} stroke={1.5} />}
				/>
				<MantineNavLink
					className={`${classes.link}`}
					onClick={() => handleSignOut()}
					leftSection={<IconLogout className={classes.linkIcon} size={25} stroke={1.5} />}
					label={open ? "Sign out" : undefined}
				/>
			</div>
		</AppShell.Navbar>
	);
}

export default Navbar;
