import { type FragmentType, gql, useFragment } from "@/__generated__";
import { SelectMerchant_MerchantFragmentDoc, SelectMerchant_QueryFragmentDoc } from "@/__generated__/graphql";
import { Select, type SelectProps } from "@mantine/core";

export const SelectMerchant_Merchant = gql(`
fragment SelectMerchant_Merchant on Merchant {
	id
	legalEntityName
}
`);

const SelectMechant_Query = gql(`
fragment SelectMerchant_Query on Query {
    merchants {
        edges {
            node {
                ...SelectMerchant_Merchant
            }
        }
    }
}
`);

interface SelectMerchantProps extends SelectProps {
	merchantData?: FragmentType<typeof SelectMechant_Query>;
}

export function SelectMerchant({ merchantData, children, ...props }: SelectMerchantProps) {
	const merchantEdges = useFragment(SelectMerchant_QueryFragmentDoc, merchantData);
	const data = merchantEdges
		? merchantEdges.merchants.edges
				.map((e) => useFragment(SelectMerchant_MerchantFragmentDoc, e.node))
				.map((m) => ({
					value: m.id,
					label: m.legalEntityName || "",
				}))
		: [];

	return (
		<Select placeholder="Select merchant" {...props} searchable data={data}>
			{children}
		</Select>
	);
}
