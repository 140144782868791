import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "mantine-react-table/styles.css";
import "./main.css";
// This comment is needed to make sure that the styles are loaded before the app is rendered
import { apolloClient } from "@/apollo";
import { FeatureFlagProvider } from "@/featureFlags/FeatureFlagProvider";
import { theme } from "@/mantine/theme";
import { createRouter } from "@/router";
import { initializeSentry } from "@/sentry";
import { ApolloProvider } from "@apollo/client";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

const root = document.getElementById("root");
if (!root) {
	throw new Error("No root element found");
}

initializeSentry();
const router = createRouter();

createRoot(root).render(
	<StrictMode>
		<ApolloProvider client={apolloClient}>
			<MantineProvider defaultColorScheme="auto" theme={theme}>
				<FeatureFlagProvider>
					<Notifications />
					<RouterProvider router={router} />
				</FeatureFlagProvider>
			</MantineProvider>
		</ApolloProvider>
	</StrictMode>,
);
