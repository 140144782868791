import { useAuth } from "@/auth/useAuth";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import type { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useEffect, useState } from "react";

export function useSignInForm() {
	const { firebaseAuth, loggedInButUserNotFound, handleSignOut } = useAuth();
	const [isLoading, setIsLoading] = useState(false);
	const [errorCode, setErrorCode] = useState("");
	const initialValues = {
		email: "",
		password: "",
	};
	const form = useForm({
		initialValues,
		validate: {
			email: isEmail("Please enter a valid email"),
			password: isNotEmpty("Password is required"),
		},
	});

	useEffect(() => {
		if (loggedInButUserNotFound) {
			setErrorCode("fastlaneiq/user-not-found");
			handleSignOut();
		}
	}, [loggedInButUserNotFound, handleSignOut]);

	const handleSubmit = (values: typeof initialValues) => {
		handleSignOut();
		setIsLoading(true);
		const { email, password } = values;
		signInWithEmailAndPassword(firebaseAuth, email, password)
			.then(() => {
				setIsLoading(false);
			})
			.catch(handleError);
	};

	const handleError = (error: FirebaseError) => {
		setIsLoading(false);
		setErrorCode(error.code);
	};

	const errorMessage = errorCode
		? {
				"auth/invalid-credential": "Invalid credentials, please try again",
				"auth/account-exists-with-different-credential":
					"This account is using a different sign inmethod. Please sign in with the method you used originally",
				"fastlaneiq/user-not-found": "fastlaneIQ account does not exist",
				"": "",
			}[errorCode] || "An unexpected error occured, please try again later"
		: "";

	return {
		form,
		onSubmit: form.onSubmit(handleSubmit),
		isLoading,
		errorCode,
		errorMessage,
		handleError,
	};
}
