import { gql } from "@/__generated__";
import { evictPlatformStageStatistics } from "@/pages/merchants/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_PROVISIONING_DATA_MUTATION = gql(`
mutation saveProvisioningData($input: SaveProvisioningDataInput!) {
	merchant {
		saveProvisioningData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...Provisioning_Merchant
				...evictPlatformStageStatistics_Merchant
			}
		}
	}
}
`);
export function useSaveProvisioningData() {
	const [saveProvisioningData, { loading, error }] = useMutation(SAVE_PROVISIONING_DATA_MUTATION, {
		update(cache, { data }) {
			const saveProvisioningData = data?.merchant.saveProvisioningData;
			if (saveProvisioningData?.success && saveProvisioningData?.merchant) {
				evictPlatformStageStatistics(cache, saveProvisioningData.merchant);
			}
		},
	});
	return {
		saveProvisioningData,
		loading,
		error,
	};
}
