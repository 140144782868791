import { gql, useFragment } from "@/__generated__";
import { AuthProvider_QueryFragmentDoc, AuthProvider_UserFragmentDoc } from "@/__generated__/graphql";
import { apolloClient } from "@/apollo";
import { AuthContext } from "@/auth/AuthContext";
import { firebaseAuth } from "@/auth/firebase";
import { getDisplayName } from "@/pages/common/utils";
import { useQuery } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { type User, onAuthStateChanged, signOut } from "firebase/auth";
import { type ReactNode, useEffect, useState } from "react";

gql(`
fragment AuthProvider_User on User {
	id
	firstName
	lastName
	email
	type
	hasWriteAccess
	platform {
		id
	}
	merchant {
		id
	}
}
`);

export const AuthProvider_QueryFragment = gql(`
fragment AuthProvider_Query on Query {
	me {
		...getDisplayName_User
		...AuthProvider_User
	}
}
`);

export const AuthProvider_getLoggedInQuery = gql(`
query AuthProvider_getLoggedInUser {
	...AuthProvider_Query
}
`);

interface AuthProviderProps {
	children: ReactNode;
}

export function AuthProvider({ children }: AuthProviderProps) {
	const [firebaseUser, setFirebaseUser] = useState<User | null>(null);
	const [initialized, setInitialized] = useState(false);
	const { loading, error, data } = useQuery(AuthProvider_getLoggedInQuery, {
		errorPolicy: "all",
		skip: !firebaseUser,
	});

	const handleSignOut = () => {
		signOut(firebaseAuth);
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(firebaseAuth, (firebaseUser) => {
			setInitialized(true);
			setFirebaseUser(firebaseUser);
			apolloClient.resetStore();
		});
		return () => unsubscribe();
	}, []);
	const queryData = useFragment(AuthProvider_QueryFragmentDoc, data) || undefined;
	const user = (firebaseUser && !loading && !error && queryData?.me) || undefined;
	const userData = useFragment(AuthProvider_UserFragmentDoc, queryData?.me) || undefined;
	const loggedInButUserNotFound = !!(firebaseUser && !loading && !error && !userData);
	const displayName = getDisplayName(user);

	useEffect(() => {
		if (userData) {
			Sentry.setUser({
				id: userData.id,
				email: userData.email,
				username: displayName,
			});
		} else {
			Sentry.setUser(null);
		}
	}, [userData, displayName]);

	return (
		<AuthContext.Provider
			value={{
				initialized: initialized && !loading && !error,
				user: userData,
				displayName,
				handleSignOut,
				firebaseAuth,
				loggedInButUserNotFound,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
