import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { useCreateNewPlatformForm } from "@/pages/platforms/createNewPlatform/useCreateNewPlatformForm";
import { Button, Flex, Modal, Stack, TextInput, Title } from "@mantine/core";

type CreateNewPlatformProps = {
	onClose: () => void;
};

export function CreateNewPlatform({ onClose }: CreateNewPlatformProps) {
	const { form, onSubmit, isDirty, loading } = useCreateNewPlatformForm(onClose);
	const { protectedClose, confirm } = usePreventClosingDirtyForm(isDirty, onClose);
	return (
		<Modal opened onClose={protectedClose} fullScreen removeScrollProps={{ allowPinchZoom: true }}>
			<form onSubmit={onSubmit}>
				<Flex justify="center" align="flex-start">
					<Stack gap="xl" w={800}>
						<Title order={1}>Create a new platform</Title>
						<div>
							<TextInput
								{...form.getInputProps("name")}
								label="Name"
								placeholder="Name"
								withAsterisk
								key={form.key("name")}
							/>
						</div>
						<Flex direction="row-reverse" gap="sm">
							<Button loading={loading} type="submit">
								Submit platform
							</Button>
							<Button type="button" variant="subtle" color="gray" onClick={protectedClose}>
								Cancel
							</Button>
						</Flex>
					</Stack>
				</Flex>
			</form>
			{confirm}
		</Modal>
	);
}
