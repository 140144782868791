import { gql, useFragment } from "@/__generated__";
import {
	UserType,
	UsersTable_QueryFragmentDoc,
	type UsersTable_UserFragment,
	UsersTable_UserFragmentDoc,
} from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { getDisplayName } from "@/pages/common/utils";
import { useDeleteUser } from "@/pages/users/useDeleteUser";
import { useUsersTableData } from "@/pages/users/usersTable/useUsersTableData";
import { paths } from "@/paths";
import { Alert, Menu } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_Row, MantineReactTable } from "mantine-react-table";
import { generatePath, useNavigate } from "react-router";

export const UsersTable_User = gql(`
fragment UsersTable_User on User {
	id
	email
	type
	hasWriteAccess
    platform {
        id
        name
    }
    merchant {
        id
        legalEntityName
    }
    ...getDisplayName_User
	...useDeleteUser_User
}
`);

export const UsersTable_Query = gql(`
fragment UsersTable_Query on Query {
	users {
        edges {
            node {
                ...UsersTable_User
            }
        }
	}
}
`);

export function UsersTable() {
	const navigate = useNavigate();
	const { user: loggedInUser } = useAuth();
	const { data, loading, error } = useUsersTableData();
	const queryData = useFragment(UsersTable_QueryFragmentDoc, data);
	const users = queryData?.users?.edges.map((edge) => useFragment(UsersTable_UserFragmentDoc, edge.node)) || [];
	const openUser = (row: MRT_Row<UsersTable_UserFragment>) => {
		navigate(generatePath(paths.userForm, { userId: row.original.id }));
	};
	const { deleteUser, confirm } = useDeleteUser();
	const table = useFastlaneTable({
		data: users,
		columns: [
			{
				accessorFn: (u) => getDisplayName(u),
				header: "Name",
				id: "name",
				size: 250,
			},
			{
				accessorKey: "email",
				size: 250,
				header: "Email",
				grow: false,
			},
			...(loggedInUser?.type === UserType.FASTLANEIQ
				? [
						{
							accessorKey: "type",
							header: "Type",
							size: 110,
							grow: false,
						},
						{
							accessorFn: (u: UsersTable_UserFragment) => u.platform?.name,
							header: "Platform",
							id: "platform",
							size: 250,
							grow: false,
						},
						{
							accessorFn: (u: UsersTable_UserFragment) => u.merchant?.legalEntityName,
							header: "Merchant",
							id: "merchant",
							size: 250,
							grow: false,
						},
					]
				: []),
			{
				accessorFn: (u: UsersTable_UserFragment) => (u.hasWriteAccess ? "Yes" : "No"),
				id: "hasWriteAccess",
				size: 200,
				header: "Has write access",
				grow: false,
			},
		],
		isLoading: loading,
		enableRowActions: loggedInUser?.hasWriteAccess,
		searchPlaceholder: "Search Users",
		renderRowActionMenuItems: ({ row }) => (
			<>
				<Menu.Item onClick={() => openUser(row)}>Edit user</Menu.Item>
				{row.original.id !== loggedInUser?.id && loggedInUser?.hasWriteAccess && (
					<Menu.Item onClick={() => deleteUser(row.original)}>Delete user</Menu.Item>
				)}
			</>
		),
	});
	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<MantineReactTable table={table} />
			{confirm}
		</div>
	);
}
