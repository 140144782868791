import { gql, useFragment } from "@/__generated__";
import {
	FilterOperator,
	type MerchantFilter,
	MerchantSearchProperty,
	type MerchantSort,
	MerchantsTable_MerchantFragmentDoc,
	SortOrder,
} from "@/__generated__/graphql";
import { useQuery } from "@apollo/client";
import type { ColumnFilter } from "@tanstack/react-table";
import type { MRT_ColumnFiltersState, MRT_SortingState } from "mantine-react-table";
import { useMemo } from "react";

export const MERCHANTS_QUERY = gql(`
query MerchantsTable_GetMerchants($search: String, $filters: [MerchantFilter!], $sort: [MerchantSort!]) {
    merchants(search: $search, filters: $filters, sort: $sort) {
        edges {
            node {
                ...MerchantsTable_Merchant
            }
        }
    }
}
`);

type useMerchantsForMerchantsTableOptions = {
	globalFilter: string;
	columnFilters: MRT_ColumnFiltersState;
	sorting: MRT_SortingState;
};

export function useMerchantsForMerchantsTable({
	globalFilter,
	columnFilters,
	sorting,
}: useMerchantsForMerchantsTableOptions) {
	const variables = {
		search: globalFilter,
		filters: formatFilters(columnFilters),
		sort: formatSort(sorting),
	};
	const { data, loading, error } = useQuery(MERCHANTS_QUERY, { variables, errorPolicy: "all" });
	const merchants = useMemo(
		() => data?.merchants?.edges.map((edge) => useFragment(MerchantsTable_MerchantFragmentDoc, edge.node)) || [],
		[data?.merchants?.edges],
	);
	return {
		merchants,
		loading,
		error,
	};
}

function formatSort(sorting: MRT_SortingState): MerchantSort[] {
	const sort = sorting.map(({ id, desc }) => ({
		prop: columnIdToMerchantSearchProperty(id),
		order: desc ? SortOrder.DESCENDING : SortOrder.ASCENDING,
	}));
	return sort;
}

function formatFilters(filters: MRT_ColumnFiltersState): MerchantFilter[] {
	return filters
		.filter(({ value }) => {
			if (value === null || value === "") {
				return false;
			}
			if (Array.isArray(value)) {
				const arrayValue = value as unknown[];
				return !arrayValue.every((v) => v === null || v === undefined || v === "");
			}
			return true;
		})
		.map((filter) => {
			const prop = columnIdToMerchantSearchProperty(filter.id);
			return {
				prop,
				value: formatFilterValue(filter),
				operator: getOperatorForProperty(prop),
			};
		});
}

function columnIdToMerchantSearchProperty(columnId: string): MerchantSearchProperty {
	switch (columnId) {
		case "legalEntityName":
			return MerchantSearchProperty.LEGAL_ENTITY_NAME;
		case "stage":
			return MerchantSearchProperty.STAGE;
		case "status":
			return MerchantSearchProperty.STATUS;
		case "leadData.annualRevenue":
			return MerchantSearchProperty.ANNUAL_REVENUE;
		case "createdAt":
			return MerchantSearchProperty.CREATED_AT;
		case "updatedAt":
			return MerchantSearchProperty.UPDATED_AT;
		case "platform.name":
			return MerchantSearchProperty.PLATFORM_NAME;
		case "createdBy":
			return MerchantSearchProperty.CREATED_BY_USER_DISPLAY_NAME;
		case "lastUpdatedBy":
			return MerchantSearchProperty.LAST_UPDATED_BY_USER_DISPLAY_NAME;
		default:
			throw new Error(`Unknown column id: ${columnId}`);
	}
}

function getOperatorForProperty(property: MerchantSearchProperty): FilterOperator {
	switch (property) {
		case MerchantSearchProperty.ANNUAL_REVENUE:
		case MerchantSearchProperty.CREATED_AT:
		case MerchantSearchProperty.UPDATED_AT:
			return FilterOperator.BETWEEN;
		case MerchantSearchProperty.STATUS:
		case MerchantSearchProperty.STAGE:
			return FilterOperator.ONE_OFF;
		default:
			return FilterOperator.CONTAINS;
	}
}

function formatFilterValue(filter: ColumnFilter): string[] {
	if (Array.isArray(filter.value)) {
		return filter.value.map(formatValue);
	}
	return [formatValue(filter.value)];
}

function formatValue(value: unknown): string {
	if (value === undefined) {
		return "";
	}
	if (value instanceof Date) {
		return value.toISOString();
	}
	return String(value);
}
