import type { Money } from "@/__generated__/graphql";
import type { MRT_Cell, MRT_RowData } from "mantine-react-table";

type MoneyCellProps<TData extends MRT_RowData> = {
	cell: MRT_Cell<TData, Money>;
};

export function MoneyCell<TData extends MRT_RowData>({ cell }: MoneyCellProps<TData>) {
	const value = cell.getValue();
	if (!value) {
		return null;
	}
	const currencyFormatter = new Intl.NumberFormat(undefined, {
		style: "currency",
		currency: value.currency,
	});
	return <span>{currencyFormatter.format(value.amount)}</span>;
}
