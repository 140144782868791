import type { MerchantsTable_MerchantFragment } from "@/__generated__/graphql";
import { snakeToHumanReadable } from "@/pages/common/utils";
import type { MRT_Cell } from "mantine-react-table";
import type { ReactNode } from "react";

type MerchantStatusCellProps = {
	renderedCellValue: ReactNode | number | string;
	cell: MRT_Cell<MerchantsTable_MerchantFragment, string>;
};

export function MerchantStageCell({ cell }: MerchantStatusCellProps) {
	const value = cell.getValue();
	return <span>{snakeToHumanReadable(value)}</span>;
}
