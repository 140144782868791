import { MerchantStage, type StagesTable_StageStatisticsFragment } from "@/__generated__/graphql";
import { Text } from "@mantine/core";
import type { MRT_Cell } from "mantine-react-table";

type AgeCellProps = {
	cell: MRT_Cell<StagesTable_StageStatisticsFragment, number | null>;
};

export function ConversionRateCell({ cell }: AgeCellProps) {
	if (cell.row.original.stage === MerchantStage.LIVE) {
		return <span>N/A</span>;
	}

	const value = cell.getValue();
	if (value === undefined || value === null) {
		return (
			<Text c="dimmed" size="sm" fs="italic">
				No data
			</Text>
		);
	}
	return (
		<span>
			{value.toLocaleString(undefined, { style: "percent", minimumFractionDigits: 0, maximumFractionDigits: 2 })}
		</span>
	);
}
