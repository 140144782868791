/// <reference types="node" />

const COMMON_CONFIG = {};

const PRODUCTION_CONFIG = {
	...COMMON_CONFIG,
	firebaseConfig: {
		apiKey: "AIzaSyCOw28tWGgANTv6TcmzsoQxEkjt0RpzSiA",
		authDomain: "app.fastlaneiq.com",
		projectId: "fastlaneiq-production",
		storageBucket: "fastlaneiq-production.appspot.com",
		messagingSenderId: "179921485663",
		appId: "1:179921485663:web:b29da321fd1f29549640ea",
	},
	emulateFirestore: false,
	graphqlUri: "https://api.fastlaneiq.com/graphql",
	environment: "production",
};

const STAGING_CONFIG = {
	...COMMON_CONFIG,
	firebaseConfig: {
		apiKey: "AIzaSyCHaTcw8GjVt8GvLOtUTstnGzikqtwGC54",
		authDomain: "app.staging.fastlaneiq.com",
		projectId: "fastlaneiq-staging",
		storageBucket: "fastlaneiq-staging.appspot.com",
		messagingSenderId: "939658176714",
		appId: "1:939658176714:web:05a9c44c7a382c105eac33",
	},
	emulateFirestore: false,
	graphqlUri: "https://api.staging.fastlaneiq.com/graphql",
	environment: "staging",
};

const LOCAL_CONFIG = {
	...COMMON_CONFIG,
	firebaseConfig: {
		...STAGING_CONFIG.firebaseConfig,
		authDomain: "fastlaneiq-staging.firebaseapp.com",
	},
	emulateFirestore: !shouldUseStagingBackendLocally(),
	graphqlUri: shouldUseStagingBackendLocally() ? STAGING_CONFIG.graphqlUri : "http://localhost:1337/graphql",
	environment: "local",
};

export type Config = typeof PRODUCTION_CONFIG | typeof STAGING_CONFIG | typeof LOCAL_CONFIG;

export function getConfig(): Config {
	if (isStaging()) {
		return STAGING_CONFIG;
	}
	if (isProduction()) {
		return PRODUCTION_CONFIG;
	}
	return LOCAL_CONFIG;
}

function isStaging() {
	return window.location.hostname.includes("app.staging.fastlaneiq.com");
}

function isProduction() {
	return window.location.hostname.includes("app.fastlaneiq.com");
}

function isLocal() {
	return window.location.hostname.includes("localhost") || window.location.hostname.includes("127.0.0.1");
}

function shouldUseStagingBackendLocally(): boolean {
	return isLocal() && import.meta.env.VITE_USE_STAGING_BACKEND === "true";
}
