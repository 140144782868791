import { type FragmentType, gql, useFragment } from "@/__generated__";
import {
	AddNewMerchantFirstInCache_MerchantFragmentDoc,
	EvictPlatformStageStatistics_MerchantFragmentDoc,
} from "@/__generated__/graphql";
import type { ApolloCache } from "@apollo/client";

const addNewMerchantFirstInCache_Merchant = gql(`
fragment addNewMerchantFirstInCache_Merchant on Merchant {
	...MerchantsTable_Merchant
	...SelectMerchant_Merchant
}
`);

export function addNewMerchantFirstInCache(
	cache: ApolloCache<FragmentType<typeof addNewMerchantFirstInCache_Merchant>>,
	merchant: FragmentType<typeof addNewMerchantFirstInCache_Merchant>,
) {
	const rootQueryId = cache.identify({ __typename: "Query" });
	const merchantData = useFragment(AddNewMerchantFirstInCache_MerchantFragmentDoc, merchant);
	cache.modify({
		id: rootQueryId,
		fields: {
			merchants: (existingMerchants) => {
				if (!existingMerchants) {
					return { edges: { node: merchantData } };
				}
				return {
					edges: [{ node: merchantData }, ...existingMerchants.edges],
				};
			},
		},
	});
}

const evictPlatformStageStatistics_Merchant = gql(`
fragment evictPlatformStageStatistics_Merchant on Merchant {
	platform {
		id
	}
}
`);

export function evictPlatformStageStatistics(
	cache: ApolloCache<FragmentType<typeof evictPlatformStageStatistics_Merchant>>,
	merchant: FragmentType<typeof evictPlatformStageStatistics_Merchant>,
) {
	const merchantData = useFragment(EvictPlatformStageStatistics_MerchantFragmentDoc, merchant);
	const platformId = merchantData?.platform?.id;
	if (!platformId) {
		return;
	}
	const cacheId = cache.identify({ __typename: "Platform", id: platformId });
	cache.evict({ id: cacheId, fieldName: "lifecycleStatistics" });
}
