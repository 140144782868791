import { useCreateNewMerchantNoteForm } from "@/pages/merchants/onboarding/merchantNotes/useCreateNewMerchantNoteForm";
import { Button, Flex, Kbd, Stack, Text, Textarea } from "@mantine/core";

type CreateNewMerchantNoteProps = {
	merchantId?: string;
	setDirty: (dirty: boolean) => void;
};

export function CreateNewMerchantNote({ merchantId, setDirty }: CreateNewMerchantNoteProps) {
	const { form, onSubmit, loading, onKeyDown, isMac } = useCreateNewMerchantNoteForm(setDirty, merchantId);
	const merchantExists = !!merchantId;
	return (
		<form style={{ display: "contents" }} onSubmit={onSubmit}>
			<Stack gap="sm">
				<Textarea
					{...form.getInputProps("message")}
					label="Message"
					onKeyDown={onKeyDown}
					withAsterisk
					placeholder={merchantExists ? "Add a note" : "Save merchant to add notes"}
					key={form.key("message")}
					disabled={!merchantExists}
					autosize
					minRows={4}
				/>
				<Flex justify="space-between">
					<Text c="dimmed" size="xs">
						<Kbd size="xs">{isMac ? "⌘" : "Ctrl"}</Kbd> + <Kbd size="xs">Enter</Kbd> to submit
					</Text>
					<Button type="submit" disabled={!merchantExists} loading={loading}>
						Submit note
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
