import { UserType } from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { paths } from "@/paths";
import {
	IconAlertCircle,
	IconBriefcase2,
	IconColumns,
	IconCropLandscape,
	IconDeviceDesktopAnalytics,
	IconHome,
} from "@tabler/icons-react";

const overview = { link: paths.overview, label: "Overview", icon: IconHome };
const stages = { link: paths.stages, label: "Stages", icon: IconColumns };
const platforms = {
	link: paths.platforms,
	label: "Platforms",
	icon: IconCropLandscape,
};
const merchants = {
	link: paths.merchants,
	label: "Merchants",
	icon: IconBriefcase2,
};
const reporting = {
	link: paths.reporting,
	label: "Reporting",
	icon: IconDeviceDesktopAnalytics,
};
const escalations = {
	link: paths.escalations,
	label: "Escalations",
	icon: IconAlertCircle,
};

export function useNavbarItems() {
	const { user } = useAuth();
	let navbarItems = [];
	if (!user || user.type === UserType.MERCHANT) {
		navbarItems = [overview, reporting];
	} else if (user.type === UserType.PLATFORM) {
		navbarItems = [overview, stages, merchants, escalations];
	} else {
		navbarItems = [overview, stages, platforms, merchants, reporting, escalations];
	}
	return { navbarItems };
}
