import { StagesTable } from "@/pages/stages/stagesTable/StagesTable";
import { Group, Stack, Title } from "@mantine/core";

export function Stages() {
	return (
		<Stack gap="xl">
			<Group justify="space-between" align="center">
				<Title order={1}>Stage breakdown</Title>
			</Group>
			<StagesTable />
		</Stack>
	);
}
