import { type FragmentType, gql, useFragment } from "@/__generated__";
import { BusinessEligibility_MerchantFragmentDoc, MerchantStage } from "@/__generated__/graphql";
import { useBusinessEligibilityForm } from "@/pages/merchants/onboarding/businessEligibility/useBusinessEligibilityForm";
import { MerchantStatusSelect } from "@/pages/merchants/onboarding/common/MerchantStatusSelector";
import { BUSINESS_ELIGIBILITY_STATUSES } from "@/pages/merchants/onboarding/prospecting/constants";
import { Button, Flex, Grid, Stack, Title } from "@mantine/core";
import { IconArrowRight } from "@tabler/icons-react";

const BusinessEligibility_MerchantFragment = gql(`
fragment BusinessEligibility_Merchant on Merchant {
	id
	stage
	status
}
`);

type BusinessEligibilityProps = {
	onClose: () => void;
	setDirty: (dirty: boolean) => void;
	merchant: FragmentType<typeof BusinessEligibility_MerchantFragment>;
};

export function BusinessEligibility({ setDirty, merchant, onClose }: BusinessEligibilityProps) {
	const merchantData = useFragment(BusinessEligibility_MerchantFragmentDoc, merchant);
	const { form, onContinue, onSave, submittingContinue, submittingSave } = useBusinessEligibilityForm(
		setDirty,
		merchantData,
	);
	return (
		<form style={{ display: "contents" }}>
			<Stack gap="xl" h="100%" justify="space-between">
				<Stack gap="lg">
					<Title order={1}>Business eligibility</Title>
					<Grid>
						<Grid.Col span={{ xs: 12, sm: 6 }}>
							<MerchantStatusSelect
								selectableStatuses={BUSINESS_ELIGIBILITY_STATUSES}
								{...form.getInputProps("status")}
								label="Status"
								key={form.key("status")}
								disabled={merchantData?.stage !== MerchantStage.BUSINESS_ELIGIBILITY}
							/>
						</Grid.Col>
					</Grid>
				</Stack>
				<Flex direction="row" justify="end" gap="sm">
					<Button type="button" variant="subtle" color="gray" onClick={onClose}>
						Cancel
					</Button>
					<Button loading={submittingSave} type="submit" onClick={onSave}>
						Save
					</Button>
					<Button
						loading={submittingContinue}
						variant="light"
						type="submit"
						onClick={onContinue}
						disabled={merchantData.stage !== MerchantStage.BUSINESS_ELIGIBILITY}
						rightSection={<IconArrowRight />}
					>
						Continue
					</Button>
				</Flex>
			</Stack>
		</form>
	);
}
