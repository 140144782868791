import type { MerchantStatus } from "@/__generated__/graphql";
import { snakeToHumanReadable } from "@/pages/common/utils";
import { Select, type SelectProps } from "@mantine/core";

interface MerchantStatusSelectProps extends SelectProps {
	selectableStatuses: MerchantStatus[];
}

export function MerchantStatusSelect({ selectableStatuses, children, ...props }: MerchantStatusSelectProps) {
	const data = selectableStatuses.map((status) => ({
		value: status,
		label: snakeToHumanReadable(status),
	}));
	return (
		<Select {...props} data={data}>
			{children}
		</Select>
	);
}
