import { useCreatePlatform } from "@/pages/platforms/createNewPlatform/useCreatePlatform";
import { isNotEmpty, useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useState } from "react";

type CreateNewPlatformFormType = {
	name: string;
};

export function useCreateNewPlatformForm(onClose: () => void) {
	const [isDirty, setDirty] = useState(false);
	const form = useForm<CreateNewPlatformFormType>({
		mode: "uncontrolled",
		initialValues: {
			name: "",
		},
		onValuesChange: () => {
			setDirty(form.isDirty());
		},
		validate: {
			name: isNotEmpty("Name is required"),
		},
	});

	const { createPlatform, loading } = useCreatePlatform();

	const onSubmit = async (values: CreateNewPlatformFormType) => {
		try {
			await createPlatform({
				variables: {
					input: {
						name: values.name,
					},
				},
			});
			notifications.show({
				title: "Platform created",
				message: "Platform successfully submitted",
			});
			onClose();
		} catch (error) {
			console.error("Error submitting form", error);
			notifications.show({
				title: "Failed to create platform",
				message: "Something went wrong while creating the platform.",
			});
		}
	};

	return {
		form,
		onSubmit: form.onSubmit(onSubmit),
		isDirty,
		loading,
	};
}
