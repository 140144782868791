import { googleAuthProvider, microsoftAuthProvider } from "@/auth/firebase";
import type { AuthProvider, CustomParameters } from "firebase/auth";

export function useFirebaseAuthProvider(provider: "google" | "microsoft", email?: string): AuthProvider {
	const params: CustomParameters = {
		prompt: "select_account",
	};
	if (email) {
		params.login_hint = email;
	}
	if (provider === "google") {
		googleAuthProvider.setCustomParameters(params);
		return googleAuthProvider;
	}
	microsoftAuthProvider.setCustomParameters(params);
	return microsoftAuthProvider;
}
