import { gql, useFragment } from "@/__generated__";
import {
	type UserForm_QueryFragment,
	UserForm_QueryFragmentDoc,
	UserForm_UserFragmentDoc,
	UserType,
} from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { SelectMerchant } from "@/components/SelectMerchant";
import { SelectPlaform } from "@/components/SelectPlaform";
import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { useUserForm } from "@/pages/users/userForm/useUserForm";
import { useUserFormData } from "@/pages/users/userForm/useUserFormData";
import { paths } from "@/paths";
import type { ApolloError } from "@apollo/client";
import {
	Alert,
	Button,
	Flex,
	Grid,
	LoadingOverlay,
	Modal,
	Select,
	Stack,
	Switch,
	TextInput,
	Title,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { generatePath, useNavigate, useParams } from "react-router";

gql(`
fragment UserForm_User on User {
	id
	email
	firstName
	lastName
	type
	hasWriteAccess
    platform {
        id
        name
    }
    merchant {
        id
        legalEntityName
    }
    ...getDisplayName_User
}
`);

gql(`
fragment UserForm_Query on Query {
    user(userId: $userId) @include(if: $userIdProvided) {
		...UserForm_User
    }
	...SelectPlaform_Query
	...SelectMerchant_Query
}
`);

export function UserForm() {
	const { userId } = useParams();
	const { data, error, loading } = useUserFormData(userId);
	const queryData = useFragment(UserForm_QueryFragmentDoc, data);
	const navigate = useNavigate();
	const onClose = () => navigate(generatePath(paths.users));
	if (loading) {
		return (
			<Modal.Root onClose={onClose} opened fullScreen removeScrollProps={{ allowPinchZoom: true }}>
				<Modal.Overlay />
				<Modal.Content>
					<LoadingOverlay visible />
				</Modal.Content>
			</Modal.Root>
		);
	}
	return <UserFormFields loading={loading} error={error} isEditing={!!userId} onClose={onClose} data={queryData} />;
}

type UserFormFieldsProps = {
	loading: boolean;
	error: ApolloError | undefined;
	isEditing: boolean;
	onClose: () => void;
	data?: UserForm_QueryFragment;
};

function UserFormFields({ loading, error, isEditing, onClose, data }: UserFormFieldsProps) {
	const user = useFragment(UserForm_UserFragmentDoc, data?.user) || undefined;
	const { form, isDirty, onSubmit, userType, loading: submittingSave } = useUserForm(user);
	const { protectedClose, confirm } = usePreventClosingDirtyForm(isDirty, onClose);
	const { user: loggedInUser } = useAuth();
	return (
		<Modal opened onClose={protectedClose} fullScreen removeScrollProps={{ allowPinchZoom: true }}>
			<form onSubmit={onSubmit}>
				<Flex justify="center" align="flex-start">
					<Stack gap="xl" w={800}>
						<Title order={1}>{isEditing ? "Edit user" : "Create a new user"}</Title>
						{loading && <LoadingOverlay visible />}
						{!loading && error && (
							<Alert icon={<IconAlertCircle />} title="Error" color="red">
								{error.message}
							</Alert>
						)}
						{!loading && !error && (
							<>
								<Grid>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("firstName")}
											label="First name"
											placeholder="First name"
											key={form.key("firstName")}
											withAsterisk
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("lastName")}
											label="Last name"
											placeholder="Last name"
											key={form.key("lastName")}
											withAsterisk
										/>
									</Grid.Col>
									<Grid.Col span={{ base: 12, sm: 6 }}>
										<TextInput
											{...form.getInputProps("email")}
											label="Email"
											placeholder="Email"
											disabled={!!isEditing}
											key={form.key("email")}
											withAsterisk
										/>
									</Grid.Col>
								</Grid>
								<Title order={4}>Access{loggedInUser?.type === UserType.FASTLANEIQ && " and membership"}</Title>
								<Grid>
									<Grid.Col span={12}>
										<Switch
											{...form.getInputProps("hasWriteAccess", { type: "checkbox" })}
											label="Has write access"
											key={form.key("hasWriteAccess")}
										/>
									</Grid.Col>
									{loggedInUser?.type === UserType.FASTLANEIQ && (
										<>
											<Grid.Col span={{ base: 12, sm: 6 }}>
												<Select
													{...form.getInputProps("userType")}
													label="User type"
													placeholder="User type"
													withAsterisk
													searchable
													allowDeselect={false}
													disabled={!!isEditing}
													data={[
														{
															value: UserType.MERCHANT,
															label: "Merchant",
														},
														{
															value: UserType.PLATFORM,
															label: "Platform",
														},
														{
															value: UserType.FASTLANEIQ,
															label: "Fastlane IQ",
														},
													]}
													key={form.key("hasWriteAccess")}
												/>
											</Grid.Col>
											{userType === UserType.PLATFORM && (
												<Grid.Col span={{ base: 12, sm: 6 }}>
													<SelectPlaform
														{...form.getInputProps("platformId")}
														label="Platform"
														placeholder="Platform"
														platformData={data}
														disabled={!!isEditing}
														withAsterisk
														key={form.key("platformId")}
													/>
												</Grid.Col>
											)}
											{userType === UserType.MERCHANT && (
												<Grid.Col span={{ base: 12, sm: 6 }}>
													<SelectMerchant
														{...form.getInputProps("merchantId")}
														label="Merchant"
														placeholder="Merchant"
														merchantData={data}
														disabled={!!isEditing}
														key={form.key("merchantId")}
														withAsterisk
													/>
												</Grid.Col>
											)}
										</>
									)}
								</Grid>
								<Flex justify="flex-end" gap="sm">
									<Button type="button" variant="subtle" color="gray" onClick={protectedClose}>
										Cancel
									</Button>
									<Button loading={submittingSave} type="submit">
										Save user
									</Button>
								</Flex>
							</>
						)}
					</Stack>
				</Flex>
			</form>
			{confirm}
		</Modal>
	);
}
