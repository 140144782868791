export function MicrosoftIcon(props: React.ComponentPropsWithoutRef<"svg">) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			style={{ width: "1em", height: "1em" }}
			viewBox="0 0 24 24"
			aria-label="Microsoft Logo"
			role="img"
			{...props}
		>
			<path fill="#F25022" d="M11.408 0H0v11.408h11.408V0Z" />
			<path fill="#7FBA00" d="M24 0H12.592v11.408H24V0Z" />
			<path fill="#00A4EF" d="M11.408 12.592H0V24h11.408V12.592Z" />
			<path fill="#FFB900" d="M24 12.592H12.592V24H24V12.592Z" />
		</svg>
	);
}
