import type { AuthProvider_UserFragment } from "@/__generated__/graphql";
import { firebaseAuth } from "@/auth/firebase";
import type { Auth } from "firebase/auth";
import { createContext } from "react";

interface AuthContextProps {
	initialized: boolean;
	user?: AuthProvider_UserFragment;
	displayName?: string;
	handleSignOut: () => void;
	firebaseAuth: Auth;
	loggedInButUserNotFound: boolean;
}

export const AuthContext = createContext<AuthContextProps>({
	initialized: false,
	user: undefined,
	displayName: undefined,
	handleSignOut: () => {
		console.log("handleSignOut not implemented yet, the provider is likely not set up correctly");
	},
	firebaseAuth,
	loggedInButUserNotFound: false,
});
