import { NativeSelect, type NativeSelectProps, NumberInput, type NumberInputProps, rem } from "@mantine/core";

const data = [
	{ value: "AUD", label: "🇦🇺 AUD" },
	{ value: "CAD", label: "🇨🇦 CAD" },
	{ value: "EUR", label: "🇪🇺 EUR" },
	{ value: "GBP", label: "🇬🇧 GBP" },
	{ value: "SEK", label: "🇸🇪 SEK" },
	{ value: "USD", label: "🇺🇸 USD" },
];

type MoneyInputProps = {
	amountProps: NumberInputProps;
	currencyProps: NativeSelectProps;
};

export function MoneyInput(props: MoneyInputProps) {
	const hasError = !!props.amountProps.error;
	const select = (
		<NativeSelect
			data={data}
			rightSectionWidth={28}
			styles={{
				root: {
					marginTop: hasError ? rem(5) : 0,
				},
				input: {
					fontWeight: 500,
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0,
					width: rem(92),
					marginRight: rem(-2),
				},
			}}
			{...props.currencyProps}
			error={hasError}
		/>
	);

	return (
		<NumberInput
			label="Hide controls"
			placeholder="Hide controls"
			hideControls
			rightSection={select}
			rightSectionWidth={92}
			thousandSeparator=","
			{...props.amountProps}
		/>
	);
}
