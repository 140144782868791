import { gql } from "@/__generated__";
import { addNewNoteFirstInCache } from "@/pages/merchants/onboarding/merchantNotes/cacheUtils";
import { useMutation } from "@apollo/client";

const CREATE_MERCHANT_NOTE_MUTATION = gql(`
mutation createMerchantNote($input: CreateMerchantNoteInput!) {
	merchantNote {
		create(input: $input) {
			success
			error {
				code
				message
			}
            note {
				...addNoteFirstInCache_MerchantNote
            }
		}
	}
}
`);

export function useCreateMerchantNote(merchantId?: string) {
	const [createMerchantNote, { loading, error }] = useMutation(CREATE_MERCHANT_NOTE_MUTATION, {
		update: (cache, { data }) => {
			const createNote = data?.merchantNote?.create;
			if (createNote?.success && createNote?.note && merchantId) {
				addNewNoteFirstInCache(cache, createNote.note, merchantId);
			}
		},
	});

	return {
		createMerchantNote,
		loading,
		error,
	};
}
