import { AuthProvider } from "@/auth/AuthProvider";
import { useAuth } from "@/auth/useAuth";
import { SignIn } from "@/pages/signIn/SignIn";
import { LoadingOverlay } from "@mantine/core";
import { Outlet } from "react-router-dom";

function AppBehindSignIn() {
	const { user, initialized } = useAuth();
	return (
		<>
			<LoadingOverlay visible={!initialized} />
			{initialized && !user && <SignIn />}
			{initialized && user && <Outlet />}
		</>
	);
}

export function App() {
	return (
		<>
			{/*
			The auth provider is added here instead of in main since it should only
			wrap the routes that require authentication.
			*/}
			<AuthProvider>
				<AppBehindSignIn />
			</AuthProvider>
		</>
	);
}
