import Navbar from "@/pages/common/Navbar";
import { AppShell } from "@mantine/core";
import { Outlet } from "react-router";

export function AppLayout() {
	return (
		<AppShell navbar={{ width: 80, breakpoint: 0 }} padding="xl">
			<Navbar />
			<AppShell.Main>
				<Outlet />
			</AppShell.Main>
		</AppShell>
	);
}
