import { MerchantStatus } from "@/__generated__/graphql";

export const PROSPECTING_STATUSES = [
	MerchantStatus.LEAD_GENERATED,
	MerchantStatus.INITIAL_CONTACT_MADE,
	MerchantStatus.INFORMATION_REQUESTED,
	MerchantStatus.INFORMATION_RECEIVED,
	MerchantStatus.DISQUALIFIED,
];

export const BUSINESS_ELIGIBILITY_STATUSES = [
	MerchantStatus.QUALIFIED_LEAD,
	MerchantStatus.ELIGIBILITY_CHECK_INITIATED,
	MerchantStatus.AWAITING_BUSINESS_INFORMATION,
	MerchantStatus.UNDER_REVIEW,
	MerchantStatus.NOT_ELIGIBLE,
	MerchantStatus.ADDITIONAL_INFORMATION_REQUIRED,
];

export const BUSINESS_VERIFICATION_STATUSES = [
	MerchantStatus.ELIGIBLE,
	MerchantStatus.KYC_AML_CHECK_INITIATED,
	MerchantStatus.DOCUMENT_COLLECTION_IN_PROGRESS,
	MerchantStatus.AWAITING_ADDITIONAL_DOCUMENTS,
	MerchantStatus.BACKGROUND_CHECK_IN_PROGRESS,
	MerchantStatus.MANUAL_REVIEW_REQUIRED,
	MerchantStatus.VERIFICATION_FAILED,
];

export const CONTRACTING_STATUSES = [
	MerchantStatus.VERIFICATION_PASSED,
	MerchantStatus.CONTRACT_SENT,
	MerchantStatus.AWAITING_SIGNATURE,
	MerchantStatus.CONTRACT_RECEIVED,
	MerchantStatus.TERMS_NEGOTIATION,
	MerchantStatus.CONTRACT_DECLINED,
];

export const PROVISIONING_STATUSES = [
	MerchantStatus.CONTRACT_APPROVED,
	MerchantStatus.ACCOUNT_SETUP_INITIATED,
	MerchantStatus.INTEGRATION_IN_PROGRESS,
	MerchantStatus.TESTING_PHASE,
	MerchantStatus.AWAITING_API_CREDENTIALS,
	MerchantStatus.TRAINING_REQUIRED,
	MerchantStatus.SETUP_COMPLETE,
];
