import { UserType } from "@/__generated__/graphql";
import { useAuth } from "@/auth/useAuth";
import { Button, Stack, Text, TextInput, Title } from "@mantine/core";
import { FirebaseError } from "firebase/app";
import { unlink, updatePassword as updateFirebasePassword } from "firebase/auth";
import { useState } from "react";

export function PasswordSignIn() {
	const { firebaseAuth, user } = useAuth();
	const enabledProviderIds = firebaseAuth.currentUser?.providerData?.map((p) => p.providerId);
	const providerId = "password";
	const isEnabled = enabledProviderIds?.includes(providerId);
	const [isLoadingDisable, setIsLoadingDisable] = useState(false);
	const [isSubmittingPassword, setIsSubmittingPassword] = useState(false);
	const [password, setPassword] = useState("");
	const disablePassword = async () => {
		if (!firebaseAuth.currentUser) {
			return;
		}
		setIsLoadingDisable(true);
		try {
			await unlink(firebaseAuth.currentUser, providerId);
			setIsLoadingDisable(false);
		} catch (error) {
			setIsLoadingDisable(false);
		}
	};

	const updatePassword = async () => {
		if (!firebaseAuth.currentUser) {
			return;
		}
		setIsSubmittingPassword(true);
		try {
			await updateFirebasePassword(firebaseAuth.currentUser, password);
			setIsSubmittingPassword(false);
		} catch (error) {
			setIsSubmittingPassword(false);
			if (error instanceof FirebaseError && error.code === "auth/requires-recent-login") {
				// TODO: Here we need to reauthenticate the user
			}
		}
	};

	if (user?.type === UserType.FASTLANEIQ) {
		return null;
	}

	return (
		<Stack>
			<Title order={4}>Email and password sign in</Title>
			<Stack maw={400}>
				{isEnabled && (
					<Button loading={isLoadingDisable} onClick={disablePassword}>
						Disable
					</Button>
				)}
				{!isEnabled && <Text>Sign in with email and password is currently disabled</Text>}
				<TextInput label="Password" type="password" onChange={(e) => setPassword(e.currentTarget.value)} />
				<Button loading={isSubmittingPassword} onClick={updatePassword}>
					{isEnabled ? "Update" : "Set"} password
				</Button>
			</Stack>
		</Stack>
	);
}
