import { gql } from "@/__generated__";
import { evictPlatformStageStatistics } from "@/pages/merchants/cacheUtils";
import { useMutation } from "@apollo/client";

const SAVE_BUSINESS_VERIFICATION_DATA_MUTATION = gql(`
mutation saveBusinessVerificationData($input: SaveBusinessVerificationDataInput!) {
	merchant {
		saveBusinessVerificationData(input: $input) {
			success
			error {
				code
				message
			}
			merchant {
				id
				...MerchantsTable_Merchant
				...BusinessVerification_Merchant
				...evictPlatformStageStatistics_Merchant
			}
		}
	}
}
`);
export function useSaveBusinessVerificationData() {
	const [saveBusinessVerificationData, { loading, error }] = useMutation(SAVE_BUSINESS_VERIFICATION_DATA_MUTATION, {
		update(cache, { data }) {
			const saveBusinessVerificationData = data?.merchant.saveBusinessVerificationData;
			if (saveBusinessVerificationData?.success && saveBusinessVerificationData?.merchant) {
				evictPlatformStageStatistics(cache, saveBusinessVerificationData.merchant);
			}
		},
	});
	return {
		saveBusinessVerificationData,
		loading,
		error,
	};
}
