import { type FragmentType, gql, useFragment } from "@/__generated__";
import { MerchantNotes_MerchantFragmentDoc } from "@/__generated__/graphql";
import { CreateNewMerchantNote } from "@/pages/merchants/onboarding/merchantNotes/CreateNewMerchantNote";
import { MerchantNote } from "@/pages/merchants/onboarding/merchantNotes/MerchantNote";
import { Box, ScrollArea, Stack, Text, Title } from "@mantine/core";
import classes from "./MerchantNotes.module.css";

export const MerchantNotes_Merchant = gql(`
fragment MerchantNotes_Merchant on Merchant{
	id
	notes {
		id
		...MerchantNote_MerchantNote
	}
}
`);

type MerchantNotesProps = {
	merchant?: FragmentType<typeof MerchantNotes_Merchant>;
	setDirty: (dirty: boolean) => void;
};

export function MerchantNotes({ merchant, setDirty }: MerchantNotesProps) {
	const merchantData = useFragment(MerchantNotes_MerchantFragmentDoc, merchant);
	const notes = merchantData?.notes || [];
	return (
		<Stack justify="space-between" className={classes.wrapper}>
			<Stack gap="lg">
				<Title order={4}>Notes</Title>
				{notes.length === 0 && (
					<Text size="sm" c="dimmed" fs="italic">
						No notes added yet
					</Text>
				)}
			</Stack>
			<ScrollArea
				type="hover"
				style={{
					height: "100%", // Set your desired height here
					overscrollBehaviorY: "contain", // Enables elastic scroll at the top and bottom
					scrollSnapType: "y mandatory", // Adds snapping effect when scrolling vertically
					WebkitOverflowScrolling: "touch", // Smooth scrolling for mobile
				}}
			>
				<Stack gap="sm">
					{notes.map((note) => (
						<MerchantNote key={note.id} note={note} />
					))}
				</Stack>
			</ScrollArea>
			<Box style={{ flexGrow: 0 }}>
				<CreateNewMerchantNote merchantId={merchantData?.id} setDirty={setDirty} />
			</Box>
		</Stack>
	);
}
