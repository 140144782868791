import { gql, useFragment } from "@/__generated__";
import { AssistedOnboarding_QueryFragmentDoc, MerchantStage } from "@/__generated__/graphql";
import { usePreventClosingDirtyForm } from "@/hooks/usePreventClosingDirtyForm";
import { BusinessEligibility } from "@/pages/merchants/onboarding/businessEligibility/BusinessEligibility";
import { BusinessVerification } from "@/pages/merchants/onboarding/businessVerification/BusinessVerification";
import { OnboardingStep } from "@/pages/merchants/onboarding/common/OnboardingStep";
import { Contracting } from "@/pages/merchants/onboarding/contracting/Contracting";
import { Live } from "@/pages/merchants/onboarding/live/Live";
import { Prospecting } from "@/pages/merchants/onboarding/prospecting/Prospecting";
import { Provisioning } from "@/pages/merchants/onboarding/provisioning/Provisioning";
import { useAssistedOnboardingData } from "@/pages/merchants/onboarding/useAssistedOnboardingData";
import { paths } from "@/paths";
import { LoadingOverlay, Modal, Stepper } from "@mantine/core";
import { useState } from "react";
import { generatePath, useNavigate, useParams } from "react-router-dom";

gql(`
fragment AssistedOnboarding_Query on Query {
    merchant(merchantId: $merchantId) @include(if: $merchantIdProvided) {
        id
		stage
		...BusinessEligibility_Merchant
		...BusinessVerification_Merchant
		...Contracting_Merchant
		...Provisioning_Merchant
		...Live_Merchant
		...OnboardingStep_Merchant
    }
	...Prospecting_Query
}
`);

const STAGE_TO_STEP = {
	[MerchantStage.PROSPECTING]: 0,
	[MerchantStage.BUSINESS_ELIGIBILITY]: 1,
	[MerchantStage.BUSINESS_VERIFICATION]: 2,
	[MerchantStage.CONTRACTING]: 3,
	[MerchantStage.PROVISIONING]: 4,
	[MerchantStage.LIVE]: 5,
	[MerchantStage.CLOSED]: 6,
};

export function AssistedOnboarding() {
	const { merchantId } = useParams();
	const navigate = useNavigate();
	const onClose = () => navigate(generatePath(paths.merchants));
	const [isDirty, setDirty] = useState({ form: false, notes: false });
	const setDirtyForm = (dirty: boolean) => setDirty((prev) => ({ ...prev, form: dirty }));
	const setDirtyNotes = (dirty: boolean) => setDirty((prev) => ({ ...prev, notes: dirty }));
	const { protectedClose, confirm } = usePreventClosingDirtyForm(isDirty.form || isDirty.notes, onClose);
	const { data, loading } = useAssistedOnboardingData(merchantId);
	const queryData = useFragment(AssistedOnboarding_QueryFragmentDoc, data);
	const merchant = queryData?.merchant || undefined;
	const merchantStep = merchant ? STAGE_TO_STEP[merchant.stage] : 0;
	const [active, setActive] = useState(-1);
	const changeStep = (step: number) => {
		if (step <= merchantStep) {
			setActive(step);
		}
	};
	return (
		<Modal.Root opened onClose={protectedClose} fullScreen>
			<Modal.Overlay />
			<Modal.Content px={{ base: "xs", xs: "xl" }} pt="lg">
				{loading && <LoadingOverlay visible />}
				{!loading && (
					<Stepper
						active={active === -1 ? merchantStep : active}
						onStepClick={changeStep}
						size="sm"
						iconSize={25}
						styles={{ content: { paddingBlockStart: 0 } }}
					>
						<Stepper.Step label="Prospecting">
							<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
								<Prospecting onClose={protectedClose} setDirty={setDirtyForm} data={queryData} />
							</OnboardingStep>
						</Stepper.Step>
						<Stepper.Step label="Business eligibility">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<BusinessEligibility onClose={protectedClose} setDirty={setDirtyForm} merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Business verification">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<BusinessVerification onClose={protectedClose} setDirty={setDirtyForm} merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Contracting">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<Contracting onClose={protectedClose} setDirty={setDirtyForm} merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Step label="Provisioning">
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<Provisioning onClose={protectedClose} setDirty={setDirtyForm} merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Step>
						<Stepper.Completed>
							{merchant && (
								<OnboardingStep merchant={merchant} setDirty={setDirtyNotes}>
									<Live merchant={merchant} />
								</OnboardingStep>
							)}
						</Stepper.Completed>
					</Stepper>
				)}
			</Modal.Content>
			{confirm}
		</Modal.Root>
	);
}
