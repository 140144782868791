import { SsoSignIn } from "@/pages/signIn/SsoSignIn";
import { useSignInForm } from "@/pages/signIn/useSignInForm";
import {
	Alert,
	AppShell,
	Box,
	Button,
	Divider,
	Group,
	Paper,
	PasswordInput,
	Stack,
	TextInput,
	Title,
} from "@mantine/core";
import classes from "./SignIn.module.css";

export function SignIn() {
	const { form, onSubmit, isLoading, errorMessage, handleError } = useSignInForm();

	return (
		<AppShell header={{ height: 50 }}>
			<AppShell.Header>
				<Group align="center" className={classes.header}>
					<Title order={2} className={classes.logo}>
						fastlaneIQ
					</Title>
				</Group>
			</AppShell.Header>
			<AppShell.Main className={classes.container}>
				<Box w={{ base: "100%", xs: 600 }} h={{ base: "100%", xs: "initial" }}>
					<Paper mih={{ base: "100%", xs: "initial" }} withBorder shadow="lg" radius="0" style={{ padding: "2rem" }}>
						<Stack gap="xl">
							<form onSubmit={onSubmit}>
								<Stack gap="lg">
									<div>
										<Title order={2}>Sign in to your fastlaneIQ account</Title>
									</div>
									{errorMessage && (
										<div>
											<Alert variant="light" color="red">
												{errorMessage}
											</Alert>
										</div>
									)}
									<TextInput
										{...form.getInputProps("email")}
										label="Email"
										placeholder="Enter your email"
										radius="md"
										size="md"
										withAsterisk
									/>
									<PasswordInput
										{...form.getInputProps("password")}
										label="Password"
										placeholder="Enter your password"
										radius="md"
										size="md"
										withAsterisk
									/>
									<Button size="md" loading={isLoading} type="submit" fullWidth>
										Sign in
									</Button>
								</Stack>
							</form>
							<Divider label="OR" />
							<Stack>
								<SsoSignIn provider="google" onError={handleError} />
								<SsoSignIn provider="microsoft" onError={handleError} />
							</Stack>
						</Stack>
					</Paper>
				</Box>
			</AppShell.Main>
		</AppShell>
	);
}
