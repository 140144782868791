import { gql, useFragment } from "@/__generated__";
import {
	type PlatformsTable_PlatformFragment,
	PlatformsTable_PlatformFragmentDoc,
	PlatformsTable_QueryFragmentDoc,
} from "@/__generated__/graphql";
import { TimestampCell } from "@/components/table/TimestampCell";
import { useFastlaneTable } from "@/components/table/useFastlaneTable";
import { getDisplayName } from "@/pages/common/utils";
import { CopySubmitLeadLinkItem } from "@/pages/platforms/platformsTable/CopySubmitLeadLinkItem";
import { usePlatformsTableData } from "@/pages/platforms/platformsTable/usePlatformsTableData";
import { Alert } from "@mantine/core";
import { IconAlertTriangle } from "@tabler/icons-react";
import { type MRT_ColumnDef, MantineReactTable } from "mantine-react-table";
import { useMemo } from "react";

export const PlatformsTable_Platform = gql(`
fragment PlatformsTable_Platform on Platform {
	id
	createdAt
	updatedAt
	name
	createdBy {
		...getDisplayName_User
	}
	lastUpdatedBy {
		...getDisplayName_User
	}
}
`);

gql(`
fragment PlatformsTable_Query on Query {
	platforms {
		edges {
			node {
				...PlatformsTable_Platform
			}
		}
	}
}
`);

export function PlatformsTable() {
	const { data, loading, error } = usePlatformsTableData();
	const platformsConnection = useFragment(PlatformsTable_QueryFragmentDoc, data);
	const platforms =
		platformsConnection?.platforms?.edges.map((edge) => useFragment(PlatformsTable_PlatformFragmentDoc, edge.node)) ||
		[];
	const columns: Array<MRT_ColumnDef<PlatformsTable_PlatformFragment>> = useMemo(
		() => [
			{
				accessorKey: "name",
				header: "Name",
			},
			{
				accessorFn: (p) => getDisplayName(p.createdBy),
				header: "Created by",
				id: "createdBy",
				size: 250,
				grow: false,
			},
			{
				header: "Created",
				accessorKey: "createdAt",
				size: 200,
				grow: false,
				Cell: TimestampCell,
			},
			{
				accessorFn: (p) => getDisplayName(p.lastUpdatedBy),
				header: "Last updated by",
				id: "lastUpdatedBy",
				size: 250,
				grow: false,
			},
			{
				header: "Last updated",
				accessorKey: "updatedAt",
				size: 200,
				grow: false,
				Cell: TimestampCell,
			},
		],
		[],
	);
	const table = useFastlaneTable<PlatformsTable_PlatformFragment>({
		data: platforms,
		columns: columns,
		searchPlaceholder: "Search platforms",
		isLoading: loading,
		enableRowActions: true,
		renderRowActionMenuItems: ({ row }) => <CopySubmitLeadLinkItem platform={row.original} />,
	});

	return (
		<div>
			{error && (
				<Alert icon={<IconAlertTriangle />} title="Error" color="red">
					{error.message}
				</Alert>
			)}
			<MantineReactTable table={table} />
		</div>
	);
}
