import { createTheme } from "@mantine/core";

export const theme = createTheme({
	fontFamily: "Roboto, sans-serif",
	headings: {
		fontFamily: "Roboto, Helvetica, Arial, sans-serif",
	},
	primaryColor: "fastlane-green",
	colors: {
		"fastlane-green": [
			"#e8fcf0",
			"#d9f3e3",
			"#b6e4c6",
			"#8fd5a8",
			"#6ec88e",
			"#59c07e",
			"#4cbc75",
			"#3ca563",
			"#319356",
			"#217f47",
		],
	},
});
